import { Misura } from '@laborability/commons';
import {
  COLORS,
  IconExportComponent,
  IconsSize,
  IconsStyle,
  LBTButton,
  LBTButtonDefaultTypography,
} from '@laborability/components';
import { Box, Link } from '@mui/material';

const ExternalSiteButton: React.FC<{ misura?: Misura; position?: string }> = ({
  misura,
  position = 'flex-end',
}) => {
  return (
    <Box display="flex" justifyContent={position}>
      <Link
        href={misura?.web_link}
        target="_blank"
        color={COLORS.getInstance().BW_GREYS_JET_BLACK}
      >
        <LBTButton
          datatestid="bonus_button_go-to-website"
          size="large"
          startIcon={
            <IconExportComponent
              datatestid="bonus_button_go-to-website"
              size={IconsSize.LARGE}
              style={IconsStyle.FILLED}
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            />
          }
          color="primary-negative"
          variant="outlined"
          onClick={() => {}}
        >
          <LBTButtonDefaultTypography datatestid="bonus_button_go-to-website">
            Vai al sito ufficiale
          </LBTButtonDefaultTypography>
        </LBTButton>
      </Link>
    </Box>
  );
};
export default ExternalSiteButton;
