import { FONTS } from '@laborability/components';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Article } from '../pages/statics/Fifth';
import { useBreakpoint } from '@laborability/commons';

const StyledGrid = styled.div<{ isDesktop: boolean }>`
  max-width: ${props => (props.isDesktop ? `1032px` : `390px`)};
  display: grid;
  grid-template-areas: ${props =>
    props.isDesktop ? `'col0 col1 col2'` : `'col0'`};
  gap: 24px;
  margin-bottom: 48px;
`;
const StyledGridItem = styled.div<{ colName?: string }>`
  grid-area: ${props => props.colName};
`;

const StyledGridItemContent = styled.div<{
  backgroundColor?: string;
  hasPadding: boolean;
  dataTrack: string;
}>`
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
  padding: ${props => (props.hasPadding ? '16px' : '0px')};
  + div {
    margin-top: 24px;
  }
  data-track: ${props => props.dataTrack};
`;

const GridItemContent = ({ article }: { article: Article }) => {
  const navigate = useNavigate();
  // const [sendEventToGA4] = useGA4();
  return (
    <StyledGridItemContent
      onClick={() => {
        // sendEventToGA4(GA4_EVENT_ACTION.CLICK_ITEM, {
        //   item: GA4_PAGE_BLOCK.STATIC_ITEM,
        //   path: article.path,
        //   title: article.title,
        // });
        navigate('/' + article.path);
      }}
      backgroundColor={article.backgroundColor}
      hasPadding={!article.image}
      dataTrack={article.dataTrack}
    >
      {article.image}
      <Typography
        fontFamily={FONTS.SPACE_GROTESK}
        fontSize={'25px'}
        fontStyle={'normal'}
        fontWeight={'900'}
        lineHeight={'30px'}
        padding={'16px 0'}
        sx={{
          '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        }}
      >
        {article.title}
      </Typography>

      <Typography
        fontFamily={FONTS.SOURCE_SANS_PRO}
        fontSize={'14px'}
        fontStyle={'normal'}
        fontWeight={'400'}
        lineHeight={'20px'}
      >
        {article.body}
      </Typography>
    </StyledGridItemContent>
  );
};

export const StaticGrid: React.FC<{ articles: Array<Article> }> = ({
  articles,
}) => {
  const { isDesktop } = useBreakpoint();
  return (
    <StyledGrid isDesktop={isDesktop}>
      {Array.from(Array(isDesktop ? 3 : 1).keys()).map(i => (
        <StyledGridItem key={i} colName={isDesktop ? 'col' + i : 'col0'}>
          {articles
            .filter(e =>
              isDesktop ? e.position.col === i : e.position.col >= 0,
            )
            .map((article, index) => (
              <GridItemContent
                key={index + '-' + i}
                article={article}
              ></GridItemContent>
            ))}
        </StyledGridItem>
      ))}
    </StyledGrid>
  );
};
