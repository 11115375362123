import {
  Misura,
  measuresState,
  searchMisuraCallback,
  useBreakpoint,
} from '@laborability/commons';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import InfiniteDiscover from '../../components/InfiniteDiscover';
import {
  CardVariant,
  COLORS,
  IconArrowRightComponent,
  IconsSize,
  IconsStyle,
  LBTCard,
  LBTTextField,
  LBTTitle,
} from '@laborability/components';
import { Grid } from '@mui/material';
import { SpaceTop } from '../../components/answerBoxes/Space';
import { ChangeScrollColor } from '../../components/ChangeScrollColor';
import { MENU_HEIGHT } from '../Home';
import Section from '../../components/Section';
import { APP_ROUTES } from '../../Routes';
import useTrackPage from '../../hooks/useTrackPage';
import { useRef, useState } from 'react';

export default function SearchMisure() {
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();
  const fetchMisure = useRecoilCallback(searchMisuraCallback, []);
  const misure = useRecoilValue(measuresState);
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string | undefined>(undefined);

  const getMisure = async (
    skip: number,
    limit: number,
    progressive: boolean,
    params?: { query?: string },
  ) => {
    const res = await fetchMisure({
      query: params?.query ?? '',
      skip,
      limit,
      published: true,
      progressive,
    });
    if (!res || !res.data) return [];
    return res.data;
  };

  useTrackPage(`Ricerca libera`, `/app/ricerca-libera`);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <ChangeScrollColor
        color={COLORS.getInstance().WHITE}
        changeBreakpoint={MENU_HEIGHT}
      />
      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
      <LBTTitle>Cerca tra tutti i bonus</LBTTitle>
      <SpaceTop margin={isDesktop ? '64px' : '48px'} />
      <LBTTextField
        ref={inputRef}
        datatestid="wide_search_input_field"
        sx={{ maxWidth: '600px' }}
        endIcon={
          <div
            onClick={() => {
              setSearch(inputRef.current?.value ?? undefined);
            }}
          >
            <IconArrowRightComponent
              datatestid="wide_search_result_link"
              size={IconsSize.MEDIUM}
              style={IconsStyle.OUTLINE}
              color={COLORS.getInstance().BLACK}
            />
          </div>
        }
      />
      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
      <InfiniteDiscover
        items={misure}
        getMoreItems={getMisure}
        params={{ query: search }}
        hasTotalItemsLabel
        renderItem={(item: Misura) => (
          <Grid item mobile={12} desktop={6} display="flex">
            <LBTCard
              onClick={() =>
                navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)
              }
              backgroundColor={COLORS.getInstance().STATUS_INFO_BLUE_MAIN}
              title={item.name}
              description={item.description}
              tag={item.categories
                ?.find(category =>
                  item.categories!.length === 2
                    ? !!category.parent_id
                    : category.parent_id === null,
                )
                ?.name?.toLowerCase()}
              news={item.news ?? false}
              expired={item.expired ?? false}
              expiringSoon={item.expiring_soon ?? false}
              cardVariant={CardVariant.CONTAINED}
              upcoming={item.upcoming ?? false}
            />
          </Grid>
        )}
      />
    </Section>
  );
}
