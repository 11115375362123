import Select, { SelectProps } from '@mui/material/Select';
import Box, { BoxProps } from '@mui/material/Box';
import React from 'react';
import styled from 'styled-components';
import {
  CheckboxStyle,
  IconArrowDownComponent,
  IconCrossComponent,
  IconWarningComponent,
  IconsSize,
  IconsStyle,
  LBTCheckbox,
} from '../..';
import { COLORS } from '../../utils/Colors';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import LBTChip from '../Chip';
import LBTLabel from '../Label';
import { KeyValue } from '@laborability/commons';

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: '12px',
      maxWidth: 'min-content',
      maxHeight: '300px',
    },
  },
  sx: { backgroundColor: 'transparent' },
};

export type LBTSelectProps = SelectProps & {
  items: (KeyValue & { description?: string; [x: string]: any })[];
  handleChange: (e: any) => void;
  helperText?: string;
  datatestid?: string;
};

const StyledSelect = styled(Select)<
  SelectProps & {
    helperText?: string;
    datatestid?: string;
    'data-track': string;
  }
>(({ helperText, datatestid, ...props }) => ({
  helperText,
  datatestid,
  'data-track': props['data-track'],
}));

const StyledIconComponentBox = styled(Box)<BoxProps>(() => ({
  width: '20px',
  height: '20px',
  marginRight: '16px',
}));

const LBTSelect: React.FC<LBTSelectProps> = ({
  items,
  handleChange,
  helperText,
  datatestid = '',
  variant = 'outlined',
  value,
  error,
  label,
  required,
  multiple,
  ...props
}: LBTSelectProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <StyledSelect
        variant={variant}
        MenuProps={MenuProps}
        onChange={e => handleChange(e.target.value)}
        value={value}
        label={label}
        IconComponent={() => (
          <StyledIconComponentBox>
            <IconArrowDownComponent
              size={IconsSize.MEDIUM}
              style={IconsStyle.OUTLINE}
              color={COLORS.getInstance().BLACK}
            />
          </StyledIconComponentBox>
        )}
        renderValue={(selected: any) => {
          return multiple ? (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((chip: any) => (
                <LBTChip
                  label={items.find(item => item.id === chip)?.name}
                  color="default"
                  size="medium"
                  onDelete={() => {
                    const array = value as Array<any>;
                    handleChange(array.filter(item => item != chip));
                  }}
                  deleteIcon={
                    <div onMouseDown={event => event.stopPropagation()}>
                      <IconCrossComponent
                        size={IconsSize.SMALL}
                        style={IconsStyle.OUTLINE}
                        color={COLORS.getInstance().BLACK}
                      />
                    </div>
                  }
                />
              ))}
            </Stack>
          ) : (
            <LBTLabel textAlign="left" fontSize="16px" lineHeight="18px">
              {items.find(item => item.id === selected)?.name ?? ''}
            </LBTLabel>
          );
        }}
        datatestid={`lbt-text-field-${datatestid}`}
        data-track={datatestid}
        required={required}
        multiple={multiple}
        {...props}
      >
        {!multiple && !required && (
          <MenuItem key={'remove_item'} value={undefined}>
            <LBTLabel>-</LBTLabel>
          </MenuItem>
        )}
        {items.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {multiple && Array.isArray(value) && (
              <LBTCheckbox
                variant={CheckboxStyle.PRIMARY}
                checked={value.indexOf(option.id) > -1}
                sx={{ marginRight: '18px', padding: '11px' }}
              />
            )}
            <Stack display="flex" flexDirection="column">
              <LBTLabel
                fontSize="16px"
                lineHeight="24px"
                textAlign="start"
                sx={{ textWrap: 'wrap' }}
              >
                {option.name}
              </LBTLabel>
              {option.description && (
                <LBTLabel
                  fontSize="14px"
                  lineHeight="16.8px"
                  color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
                  textAlign="start"
                  sx={{ textWrap: 'wrap' }}
                >
                  {option.description}
                </LBTLabel>
              )}
            </Stack>
          </MenuItem>
        ))}
      </StyledSelect>
      <FormHelperText>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {error && (
            <IconWarningComponent
              size={IconsSize.MEDIUM}
              style={IconsStyle.OUTLINE}
              color={COLORS.getInstance().ERROR_MAIN}
            />
          )}
          <LBTLabel
            color={
              error
                ? COLORS.getInstance().ERROR_MAIN
                : COLORS.getInstance().BW_GREYS_MEDIUM
            }
            fontSize="12px"
            lineHeight="14px"
            style={{ textAlign: 'left' }}
          >
            {helperText}
          </LBTLabel>
        </Box>
      </FormHelperText>
    </FormControl>
  );
};

export default LBTSelect;
