import { createTheme } from '@mui/material/styles';
import '../assets/font.css';
import '../assets/fonts/source-sans-pro/source_sans_pro/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_black/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_black_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_bold/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_bold_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_extralight/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_extralight_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_light/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_light_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_semibold/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_semibold_italic/stylesheet.css';
import { COLORS } from './Colors';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    mintTonic?: PaletteOptions['primary'];
    'lemon-cake'?: PaletteOptions['primary'];
    berryPopsicle?: PaletteOptions['primary'];
    lavander?: PaletteOptions['primary'];
    secondario?: PaletteOptions['primary'];
    'primary-negative'?: PaletteOptions['primary'];
    negative?: PaletteOptions['primary'];
  }
  interface PaletteColor {
    superdark?: string;
    hover?: string;
    superlight?: string;
    iperlight?: string;
  }

  interface SimplePaletteColorOptions {
    superlight?: string;
    iperlight?: string;
    darker?: string;
    hover?: string;
    selected?: string;
    focus?: string;
    focusVisible?: string;
    outlinedBorder?: string;
    neutral?: string;
    'primary-negative'?: string;
    negative?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    invisible: true;
    filter: true;
  }
  interface ButtonPropsSizeOverrides {
    invisible: true;
  }
  interface ButtonPropsColorOverrides {
    'primary-negative': true;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsVariantOverrides {
    contained: true;
    negative: true;
  }
  interface AlertPropsColorOverrides {
    neutral: true;
  }
  interface AlertPropsSeverityOverrides {
    primary: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    negative: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    'lemon-cake': true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    smartphone: true;
    tablet: true;
    desktop: true;
  }
}

export enum FONTS {
  SOURCE_SANS_PRO = '"Source Sans Pro"',
  SOURCE_SANS_PRO_BOLD = '"Source Sans Pro Bold"',
  SPACE_GROTESK = '"Space Grotesk"',
  DELA_GOTHIC_ONE = '"Dela Gothic One"',
}

export const AppTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      smartphone: 599,
      tablet: 719,
      desktop: 839,
    },
  },
  components: {
    MuiButton: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
          fontWeight: 400,
          fontStyle: 'normal',
          textTransform: 'none',
          borderRadius: '10px',
          padding: '8px 16px',
          boxShadow: 'none !important',
          ...(ownerState.variant === 'filter' && {
            color: theme.palette.primary.dark,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.primary.dark}`,
            '&:hover': {
              color: theme.palette.primary.dark,
              backgroundColor: theme.palette.primary.iperlight,
            },
            '&:active': {
              backgroundColor: theme.palette.primary.superlight,
              border: `1px solid ${theme.palette.primary.dark}`,
            },
            '&:disabled': {
              color: COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT,
              backgroundColor: 'transparent',
              border: `1px solid ${COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT}`,
            },
          }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'contained' && {
              color: COLORS.getInstance().BW_GREYS_JET_BLACK,
              backgroundColor: theme.palette.primary.main,
              border: `1px solid transparent`,
              '&:hover': {
                color: COLORS.getInstance().BW_GREYS_JET_BLACK,
                backgroundColor: theme.palette.primary.light,
              },
              '&:active': {
                backgroundColor: theme.palette.primary.superlight,
                border: `1px solid ${theme.palette.primary.dark}`,
              },
              '&:disabled': {
                color: COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT,
                backgroundColor: COLORS.getInstance().BW_GREYS_SUPERLIGHT,
              },
            }),
          ...(ownerState.color === 'primary-negative' &&
            ownerState.variant === 'contained' && {
              color: COLORS.getInstance().WHITE,
              backgroundColor: COLORS.getInstance().BW_GREYS_JET_BLACK,
              border: `1px solid transparent`,
              '&:hover': {
                color: COLORS.getInstance().WHITE,
                backgroundColor: COLORS.getInstance().BW_GREYS_DARK,
              },
              '&:active': {
                backgroundColor: COLORS.getInstance().BW_GREYS_MEDIUM,
                border: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
              '&:disabled': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
              },
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'outlined' && {
              color: theme.palette.primary.dark,
              backgroundColor: 'transparent',
              border: `1px solid ${theme.palette.primary.dark}`,
              '&:hover': {
                color: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.iperlight,
              },
              '&:active': {
                backgroundColor: theme.palette.primary.superlight,
                border: `2px solid ${theme.palette.primary.dark}`,
              },
              '&:disabled': {
                color: COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT,
                backgroundColor: 'transparent',
                border: `2px solid ${COLORS.getInstance().BW_GREYS_SUPERLIGHT}`,
              },
            }),
          ...(ownerState.color === 'primary-negative' &&
            ownerState.variant === 'outlined' && {
              color: COLORS.getInstance().BW_GREYS_JET_BLACK,
              backgroundColor: 'transparent',
              border: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              '&:hover': {
                color: COLORS.getInstance().BW_GREYS_JET_BLACK,
                backgroundColor: `${COLORS.getInstance().BLACK}1A`,
                border: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
              '&:active': {
                border: `2px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
                backgroundColor: `${COLORS.getInstance().BLACK}33`,
              },
              '&:disabled': {
                color: theme.palette.primary.main,
                backgroundColor: 'transparent',
                border: `1px solid ${theme.palette.primary.main}`,
              },
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'invisible' && {
              color: theme.palette.primary.dark,
              backgroundColor: 'transparent',
              border: `1px solid transparent`,
              '&:hover': {
                color: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.iperlight,
              },
              '&:active': {
                border: `1px solid ${theme.palette.primary.dark}`,
                backgroundColor: theme.palette.primary.superlight,
              },
              '&:disabled': {
                color: COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT,
              },
            }),
          ...(ownerState.color === 'primary-negative' &&
            ownerState.variant === 'invisible' && {
              color: COLORS.getInstance().BW_GREYS_JET_BLACK,
              backgroundColor: 'transparent',
              border: `1px solid transparent`,
              '&:hover': {
                color: COLORS.getInstance().BW_GREYS_JET_BLACK,
                backgroundColor: `${COLORS.getInstance().BLACK}1A`,
              },
              '&:active': {
                border: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
                backgroundColor: `${COLORS.getInstance().BLACK}33`,
              },
              '&:disabled': {
                color: theme.palette.primary.main,
              },
            }),
        }),
        sizeLarge: {
          height: '56px',
        },
        sizeMedium: {
          height: '47px',
        },
        sizeSmall: {
          height: '36px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '& .MuiAlert-icon': {
            paddingTop: '7px',
          },
          '& .MuiAlert-message': {
            padding: '5px 0px 8px 0px',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'contained', severity: 'error' },
          style: {
            background: `${COLORS.getInstance().ERROR_MAIN}`,
            color: 'white',
          },
        },
        {
          props: { variant: 'contained', severity: 'success' },
          style: {
            background: `${COLORS.getInstance().SUCCESS_MAIN}`,
            color: 'white',
          },
        },
        {
          props: { variant: 'contained', severity: 'warning' },
          style: {
            background: `${COLORS.getInstance().WARNING_MAIN}`,
            color: 'white',
          },
        },
        {
          props: { variant: 'contained', severity: 'info' },
          style: {
            background: `${COLORS.getInstance().INFO_MAIN}`,
            color: 'white',
          },
        },
        {
          props: { variant: 'contained', severity: 'neutral' },
          style: {
            background: `${COLORS.getInstance().NEUTRAL}`,
            color: 'white',
          },
        },
        {
          props: { variant: 'negative', severity: 'error' },
          style: {
            background: `${COLORS.getInstance().WHITE}`,
            color: `${COLORS.getInstance().ERROR_MAIN}`,
          },
        },
        {
          props: { variant: 'negative', severity: 'success' },
          style: {
            background: `${COLORS.getInstance().WHITE}`,
            color: `${COLORS.getInstance().SUCCESS_MAIN}`,
          },
        },
        {
          props: { variant: 'negative', severity: 'warning' },
          style: {
            background: `${COLORS.getInstance().WHITE}`,
            color: `${COLORS.getInstance().WARNING_MAIN}`,
          },
        },
        {
          props: { variant: 'negative', severity: 'info' },
          style: {
            background: `${COLORS.getInstance().WHITE}`,
            color: `${COLORS.getInstance().PRIMARY_SUPERDARK}`,
          },
        },
        {
          props: { variant: 'negative', severity: 'neutral' },
          style: {
            background: `${COLORS.getInstance().WHITE}`,
            color: `${COLORS.getInstance().NEUTRAL}`,
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().INFO_MAIN}`,
              color: `${COLORS.getInstance().INFO_MAIN}`,
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'filled' && {
              background: `${COLORS.getInstance().INFO_MAIN}`,
              '& .MuiChip-label': {
                color: `${COLORS.getInstance().WHITE}`,
              },
            }),
          ...(ownerState.color === 'lemon-cake' &&
            ownerState.variant === 'filled' && {
              background: `${COLORS.getInstance().LEMON_CAKE_MAIN}`,
              '& .MuiChip-label': {
                color: `${COLORS.getInstance().BLACK}`,
              },
            }),
        }),
      },
    },
    MuiSwitch: {
      variants: [
        {
          props: { color: 'negative' },
          style: {
            '&.MuiSwitch-root': {
              '& .Mui-checked': {
                '& .MuiSwitch-thumb': {
                  color: `${COLORS.getInstance().NEGATIVE}`,
                },
                '+ .MuiSwitch-track': {
                  backgroundColor: `${COLORS.getInstance().BW_GREYS_DARK}`,
                },
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.disabled === false && {
            '&:hover': {
              '& .MuiSwitch-thumb': {
                outline: `1px solid ${COLORS.getInstance().BLACK}`,
              },
              '& .Mui-checked': {
                '& .MuiSwitch-thumb': {
                  outline: 'none',
                },
              },
            },
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '& .MuiInputLabel-root': {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            '&.Mui-focused': {
              color: COLORS.getInstance().BW_GREYS_MEDIUM,
            },
          },

          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            fontFamily: FONTS.SOURCE_SANS_PRO,
            backgroundColor: COLORS.getInstance().WHITE,

            '&.Mui-focused fieldset': {
              borderColor: COLORS.getInstance().INFO_MAIN,
            },
          },
          '& .MuiFormHelperText-root': {
            fontFamily: FONTS.SOURCE_SANS_PRO,
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: '250px',
          borderRadius: '12px',
          '& .MuiInputLabel-root': {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            '&.Mui-focused': {
              color: COLORS.getInstance().BW_GREYS_MEDIUM,
            },
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: FONTS.SOURCE_SANS_PRO,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: FONTS.SOURCE_SANS_PRO,
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: () => ({
          background: `${COLORS.getInstance().BLACK}CC`,
        }),
      },
    },
  },
  palette: {
    primary: {
      main: COLORS.getInstance().PRIMARY_MAIN,
      dark: COLORS.getInstance().PRIMARY_DARK,
      light: COLORS.getInstance().PRIMARY_LIGHT,
      superlight: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      iperlight: COLORS.getInstance().PRIMARY_IPERLIGHT,
      contrastText: COLORS.getInstance().PRIMARY_CONTRAST,
      hover: COLORS.getInstance().PRIMARY_IPERLIGHT,
      selected: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      focus: COLORS.getInstance().PRIMARY_LIGHT,
      focusVisible: '#7098F6',
      outlinedBorder: '#6986F0',
    },
    'primary-negative': {
      main: COLORS.getInstance().PRIMARY_MAIN,
      dark: COLORS.getInstance().PRIMARY_DARK,
      light: COLORS.getInstance().PRIMARY_LIGHT,
      superlight: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      iperlight: COLORS.getInstance().PRIMARY_IPERLIGHT,
      contrastText: COLORS.getInstance().PRIMARY_CONTRAST,
      hover: COLORS.getInstance().PRIMARY_IPERLIGHT,
      selected: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      focus: COLORS.getInstance().PRIMARY_LIGHT,
      focusVisible: '#7098F6',
      outlinedBorder: '#6986F0',
    },
    negative: {
      main: COLORS.getInstance().PRIMARY_MAIN,
      dark: COLORS.getInstance().PRIMARY_DARK,
      light: COLORS.getInstance().PRIMARY_LIGHT,
      superlight: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      iperlight: COLORS.getInstance().PRIMARY_IPERLIGHT,
      contrastText: COLORS.getInstance().PRIMARY_CONTRAST,
      hover: COLORS.getInstance().PRIMARY_IPERLIGHT,
      selected: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      focus: COLORS.getInstance().PRIMARY_LIGHT,
      focusVisible: '#7098F6',
      outlinedBorder: '#6986F0',
    },
    secondary: {
      main: COLORS.getInstance().SECONDARY_MAIN,
      dark: COLORS.getInstance().SECONDARY_DARK,
      light: COLORS.getInstance().SECONDARY_LIGHT,
      contrastText: COLORS.getInstance().SECONDARY_CONTRAST,
    },

    mintTonic: {},
    'lemon-cake': {
      main: COLORS.getInstance().LEMON_CAKE_MAIN,
      dark: COLORS.getInstance().LEMON_CAKE_MAIN,
      light: COLORS.getInstance().LEMON_CAKE_MAIN,
      contrastText: COLORS.getInstance().LEMON_CAKE_MAIN,
    },
    berryPopsicle: {},
    lavander: {},
    secondario: {},
    error: {
      main: COLORS.getInstance().ERROR_MAIN,
      dark: COLORS.getInstance().ERROR_DARK,
      light: COLORS.getInstance().ERROR_LIGHT,
      contrastText: COLORS.getInstance().ERROR_CONTRAST,
    },
    warning: {
      main: COLORS.getInstance().WARNING_MAIN,
      dark: COLORS.getInstance().WARNING_DARK,
      light: COLORS.getInstance().WARNING_LIGHT,
      contrastText: COLORS.getInstance().WARNING_CONTRAST,
    },
    info: {
      main: COLORS.getInstance().INFO_MAIN,
      dark: COLORS.getInstance().INFO_DARK,
      light: COLORS.getInstance().INFO_DARK,
      contrastText: COLORS.getInstance().INFO_CONTRAST,
    },
    success: {
      main: COLORS.getInstance().SUCCESS_MAIN,
      dark: COLORS.getInstance().SUCCESS_DARK,
      light: COLORS.getInstance().SUCCESS_LIGHT,
      contrastText: COLORS.getInstance().SUCCESS_CONTRAST,
    },
    neutral: {
      main: COLORS.getInstance().NEUTRAL,
      dark: COLORS.getInstance().NEUTRAL,
      light: COLORS.getInstance().NEUTRAL,
      contrastText: COLORS.getInstance().WHITE,
    },
  },
});
