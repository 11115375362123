import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { IconCheckbox } from '../Icons';
import styled from 'styled-components';
import { CheckboxStyle, CheckboxType } from '../../enums';

export type LBTCheckboxProps = CheckboxProps & {
  variant: CheckboxStyle;
  datatestid?: string;
};

const StyledCheckbox = styled(Checkbox)<
  CheckboxProps & {
    variant: CheckboxStyle;
    datatestid?: string;
  }
>(({ theme, variant, datatestid }) => ({
  variant,
  datatestid,
}));

const LBTCheckbox: React.FC<LBTCheckboxProps> = ({
  variant,
  datatestid = '',
  ...props
}: LBTCheckboxProps) => {
  return (
    <StyledCheckbox
      icon={
        <IconCheckbox
          style={variant}
          type={CheckboxType.UNCHECKED}
          disabled={props.disabled}
        />
      }
      checkedIcon={
        <IconCheckbox
          style={variant}
          type={CheckboxType.CHECKED}
          disabled={props.disabled}
        />
      }
      indeterminateIcon={
        <IconCheckbox
          style={variant}
          type={CheckboxType.INTERMEDIATE}
          disabled={props.disabled}
        />
      }
      datatestid={`lbt-checkbox-${datatestid}`}
      variant={variant}
      {...props}
    />
  );
};

export default LBTCheckbox;
