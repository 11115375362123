import {
  COLORS,
  FONTS,
  DefaultAccordionSummaryTypography,
  LBTAccordion,
  LBTTitle,
  LBTSubtitle,
} from '@laborability/components';
import { Typography } from '@mui/material';
import Section from '../../components/Section';
import { StaticArticles } from '../../components/StaticArticles';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ChangeScrollColor } from '../../components/ChangeScrollColor';
import { SolaryFits } from '../../components/SolaryFits';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxOne,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationFourthTwoBoxes';
import { SpaceTop } from '../../components/answerBoxes/Space';
import { navMenuState } from '../../recoil/states/global';
import { MENU_HEIGHT } from '../Home';
import { ARTICLES } from './Fifth';
import { useBreakpoint } from '@laborability/commons';
import useTrackPage from '../../hooks/useTrackPage';

const ACCORDION_ITEMS = [
  {
    question: 'Come si ottiene il rimborso?',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'A chi si può chiedere tutela?',
    answer: <AnswerBoxTwo />,
  },
];

const Fifth04 = () => {
  const { isDesktop } = useBreakpoint();
  const setNavColor = useSetRecoilState(navMenuState);

  useEffect(() => {
    setNavColor(COLORS.getInstance().WHITE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackPage(
    'Cessione del quinto - Articolo 4',
    '/app/cessione-quinto/articol-4',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <ChangeScrollColor
          color={COLORS.getInstance().WHITE}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle color={COLORS.getInstance().MINT_TONIC_MEDIUM}>
          Come chiedere il rimborso della cessione del quinto?
        </LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTSubtitle>
          Cos'è il rimborso della cessione del quinto, come si può ottenere e a
          chi chiedere tutela
        </LBTSubtitle>
        {ARTICLES[3].image}
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        <Typography
          fontFamily={FONTS.SOURCE_SANS_PRO}
          fontSize={'16px'}
          fontStyle={'normal'}
          fontWeight={'400'}
          lineHeight={'22px'}
          paddingBottom={'16px'}
          paddingTop={'48px'}
          maxWidth={'680px'}
        >
          La cessione del quinto dello stipendio è un modo con cui un soggetto
          può ripagare un prestito contratto con un istituto finanziario cedendo
          a questo una parte della propria retribuzione mensile. Le rate
          coincidono, quindi, con la quota di stipendio ceduta. L'istituto
          finanziario non si limita a pretendere la restituzione delle somme
          prestate, ma chiede anche il pagamento di spese (spese di incasso),
          commissioni (per il perfezionamento del finanziamento, per la gestione
          del finanziamento, costi assicurativi) e interessi. Il <SpaceTop />
          <BoldSpan>
            finanziamento può essere estinto prima del termine
          </BoldSpan>
          previsto, nel caso in cui il debito residuo venga interamente pagato o
          nel caso in cui il finanziamento venga rinegoziato (rinnovo della
          cessione con estinzione della precedente).
          <SpaceTop /> In questi casi il debitore ha diritto alla{' '}
          <BoldSpan>riduzione del costo totale del credito</BoldSpan>, il quale
          include tutti i costi posti a suo carico al momento della concessione
          del finanziamento.
          <SpaceTop />
          Il <BoldSpan>diritto al rimborso</BoldSpan> è stabilito dal Testo
          Unico Bancario e dalla Direttiva Europea sul credito al consumo, e le
          clausole che escludono tale diritto sono nulle. dovutagli dall'INPS.
        </Typography>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            uniqueKey={`${index}`}
            details={item.answer}
            summary={
              <DefaultAccordionSummaryTypography children={item.question} />
            }
          ></LBTAccordion>
        ))}
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <ChangeScrollColor
          color={COLORS.getInstance().WHITE}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>Vai ai prossimi articoli</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <StaticArticles toExclude={[3]}></StaticArticles>
      </Section>
    </>
  );
};

export default Fifth04;
