import { Box, Modal, ModalProps, Stack, Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/Colors';
import IconCrossComponent from '../Icons/IconCrossComponent';
import { IconsSize, IconsStyle } from '../../enums';
import { FONTS } from '../../utils';

export type LBTModalProps = ModalProps & {
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  open: boolean;
  datatestid?: string;
  onXClose?: () => void;
  isDesktop?: boolean;
};

const StyledModal = styled(Modal)<ModalProps & {}>(({ theme }) => ({}));

const StyledBox = styled(Box)`
  cursor: pointer;
`;

const LBTModal: React.FC<LBTModalProps> = ({
  children,
  open = false,
  title,
  subtitle1,
  subtitle2,
  datatestid = 'lbt-modal',
  isDesktop = false,
  ...props
}: LBTModalProps) => {
  return (
    <StyledModal open={open} {...props}>
      <Stack
        style={{
          position: 'absolute',
          top: isDesktop ? '50%' : 0,
          left: isDesktop ? '50%' : 0,
          transform: isDesktop ? 'translate(-50%, -50%)' : undefined,
          width: isDesktop ? '900px' : '100%',
          height: isDesktop ? undefined : '100%',
          ...(isDesktop ? { maxWidth: '900px' } : { maxWidth: '100%' }),
          border: isDesktop ? '1px solid #000' : undefined,
          backgroundColor: COLORS.getInstance().WHITE,
        }}
      >
        <Stack
          style={{
            height: '100%',
          }}
        >
          <Stack
            sx={{
              borderBottom: '1px solid #DDDDDD',
              padding: '24px 0',
              margin: isDesktop ? '0 22px' : '0 16px',
            }}
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              sx={{ width: 1 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
                sx={{ flex: 1, textAlign: 'center' }}
                fontSize={'20px'}
                fontWeight={'700'}
                padding="7px"
              >
                {title}
              </Typography>
              <StyledBox
                onClick={() =>
                  'onXClose' in props &&
                  typeof props.onXClose === 'function' &&
                  props.onXClose()
                }
              >
                <IconCrossComponent
                  color="#000"
                  size={IconsSize.MEDIUM}
                  style={IconsStyle.FILLED}
                />
              </StyledBox>
            </Stack>
            {subtitle1 && (
              <Typography variant="subtitle1">{subtitle1}</Typography>
            )}
            {subtitle2 && (
              <Typography variant="subtitle2">{subtitle2}</Typography>
            )}
          </Stack>

          <Box
            sx={{
              padding: isDesktop ? '24px 22px' : '24px 16px',
              overflow: 'auto',
            }}
          >
            {children}
          </Box>
        </Stack>
      </Stack>
    </StyledModal>
  );
};

export default LBTModal;
