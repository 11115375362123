import {
  COLORS,
  FONTS,
  DefaultAccordionSummaryTypography,
  LBTAccordion,
  LBTTitle,
  LBTSubtitle,
} from '@laborability/components';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ChangeScrollColor } from '../../components/ChangeScrollColor';
import Section from '../../components/Section';
import { SolaryFits } from '../../components/SolaryFits';
import { StaticArticles } from '../../components/StaticArticles';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import { AnswerBoxOne } from '../../components/answerBoxes/FifthSalaryTerminationFifthBoxes';
import { SpaceTop } from '../../components/answerBoxes/Space';
import { navMenuState } from '../../recoil/states/global';
import { MENU_HEIGHT } from '../Home';
import { ARTICLES } from './Fifth';
import { useBreakpoint } from '@laborability/commons';
import useTrackPage from '../../hooks/useTrackPage';

const ACCORDION_ITEMS = [
  {
    question: 'In relazione al TFR',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Cosa succede se cambio lavoro?',
    answer: <AnswerBoxOne />,
  },
];

const Fifth05 = () => {
  const { isDesktop } = useBreakpoint();
  const setNavColor = useSetRecoilState(navMenuState);

  useEffect(() => {
    setNavColor(COLORS.getInstance().PRIMARY_SUPERLIGHT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackPage(
    'Cessione del quinto - Articolo 5',
    '/app/cessione-quinto/articol-5',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().PRIMARY_SUPERLIGHT}>
        <ChangeScrollColor
          color={COLORS.getInstance().PRIMARY_SUPERLIGHT}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>
          Cessione del quinto, cosa succede alla fine del rapporto?
        </LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTSubtitle>
          Sono previsti precisi obblighi a carico dell'azienda nel caso in cui
          il finanziamento non sia stato ancora interamente pagato
        </LBTSubtitle>
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        {ARTICLES[4].image}
        <Typography
          fontFamily={FONTS.SOURCE_SANS_PRO}
          fontSize={'16px'}
          fontStyle={'normal'}
          fontWeight={'400'}
          lineHeight={'22px'}
          paddingBottom={'16px'}
          paddingTop={'48px'}
          maxWidth={'680px'}
        >
          Al <BoldSpan>termine del rapporto</BoldSpan> è possibile che il
          lavoratore <BoldSpan>non abbia</BoldSpan> ancora finito di pagare
          interamente il <BoldSpan>finanziamento</BoldSpan> ottenuto mediante la
          cessione del quinto dello stipendio.
          <SpaceTop />
          Per evitare che la cessazione del rapporto pregiudichi l'istituto
          finanziatore sono previsti{' '}
          <BoldSpan>precisi obblighi a carico dell'azienda</BoldSpan>.
          <SpaceTop />
          Trattasi di <BoldSpan>una forma di finanziamento</BoldSpan>
          per i lavoratori subordinati. Funziona in modo molto semplice: un
          <BoldSpan>dipendente ottiene del denaro in prestito</BoldSpan> da una
          banca o da una finanziaria, che restituisce, mensilmente, mediante la{' '}
          <BoldSpan>cessione di una parte del proprio stipendio</BoldSpan>.
          <SpaceTop />
          Dopo aver scelto l'istituto finanziario, il lavoratore deve dimostrare
          di avere i requisiti necessari per pagare il suo debito con questa
          formula.
          <SpaceTop />
          Nello specifico, deve avere un rapporto di lavoro a{' '}
          <BoldSpan>tempo indeterminato</BoldSpan>, anche parziale, o
          <BoldSpan>parasubordinato</BoldSpan> (Co.Co.Co, agenti e
          rappresentanti di commercio) e ricevere uno{' '}
          <BoldSpan>stipendio fisso e continuativo</BoldSpan>.
          <SpaceTop />
          <BoldSpan>Non può</BoldSpan> cedere il quinto dello stipendio il
          lavoratore che si trova nelle seguenti condizioni:
          <ul>
            <li>
              in <BoldSpan>aspettativa non retribuita</BoldSpan>
            </li>
            <li>
              {' '}
              in <BoldSpan>congedo non retribuito</BoldSpan>
            </li>
            <li>
              con <BoldSpan>età superiore a 65 anni</BoldSpan>
            </li>
            <li>
              ha <BoldSpan>sottoscritto</BoldSpan> un{' '}
              <BoldSpan>divieto di cessione dello stipendio</BoldSpan> con il
              proprio datore di lavoro.
            </li>
          </ul>
          <SpaceTop />
          Essa avviene in contemporanea rispetto all'erogazione del
          finanziamento. Lo stipendio, dunque, rappresenta la{' '}
          <BoldSpan>garanzia di adempimento</BoldSpan> del prestito. Finché dura
          il rapporto lavorativo, la finanziaria riceve mensilmente la quota di
          retribuzione a copertura delle somme prestate. E se il rapporto di
          lavoro finisce?
        </Typography>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            uniqueKey={`${index}`}
            details={item.answer}
            summary={
              <DefaultAccordionSummaryTypography children={item.question} />
            }
          ></LBTAccordion>
        ))}
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <ChangeScrollColor
          color={COLORS.getInstance().WHITE}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>Vai ai prossimi articoli</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <StaticArticles toExclude={[4]}></StaticArticles>
      </Section>
    </>
  );
};

export default Fifth05;
