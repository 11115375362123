import axios from 'axios';

export const baseURL = `${process.env.REACT_APP_API_URL}`;
// default axios configuration
// axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

export enum PATH {
  ACTIONS = 'actions',
  BENEFITS = 'benefit-types',
  CATEGORIES = 'categories',
  CLIENTI = 'clienti',
  COMPANIES = 'companies',
  COMUNI = 'municipalities',
  CONTRACTS = 'contracts',
  DOMAINS = 'domains',
  ENTE = 'agencies',
  FLOWS = 'flows',
  MISURE = 'measures',
  NOTHIGN = '',
  PAGES = 'pages',
  PROFILES = 'profiles',
  PROVINCE = 'provinces',
  PROVINCIA = 'province',
  PUBLIC = 'public',
  QUESTIONS = 'questions',
  REGIONI = 'regions',
  REGISTER = 'register',
  STATUS_MEASURE = 'status_measure',
  SUB_CATEGORIES = 'sub_categories',
  TAGS = 'tags',
}

// axios.defaults.headers.post['Content-Type'] = 'application/json';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
  // transformRequest: [
  //   data => {
  //     return JSON.stringify(data);
  //   },
  // ],
  transformResponse: [
    data => {
      return JSON.parse(data);
    },
  ],
});

// axios.interceptors.response.use(
//   response => {
//     if (response.status === 401) {
//       alert('You are not authorized');
//     }
//     return response;
//   },
//   error => {
//     if (error.response && error.response.data) {
//       return Promise.reject(error.response.data);
//     }
//     return Promise.reject(error.message);
//   },
// );

export default API;

// export default axios.create({
//   baseURL,
// });

// axios.interceptors.request.use(
//   function (req) {
//     req.time = { startTime: new Date() };
//     return req;
//   },
//   (err) => {
//     return Promise.reject(err);
//   }
// );

// axios.interceptors.response.use(
//   function (res) {
//     res.config.time.endTime = new Date();
//     res.duration =
//       res.config.time.endTime - res.config.time.startTime;
//     return res;
//   },
//   (err) => {
//     return Promise.reject(err);
//   }
// );
