import { useRef } from 'react';
import useScrollColor from '../hooks/useScollColor';

export const ChangeScrollColor: React.FC<{
  color: string;
  changeBreakpoint: number;
}> = ({ color, changeBreakpoint }) => {
  const startRef = useRef<HTMLDivElement>(null);
  useScrollColor(startRef, changeBreakpoint, color);

  return (
    <>
      <span ref={startRef}></span>
    </>
  );
};
