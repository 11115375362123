import { Ente } from './ente';

export type Company = {
  id?: number;
  name?: string;
  vat_number?: string;
  phone_number?: string;
  user_ids?: Array<number>;
  address?: string;
  postal_code?: string;
  region_id?: number;
  province_id?: number;
  city_id?: number;
  country?: string;
  email?: string;
  agency_ids?: number[];
  contract_ids?: number[];
  bank_details?: string;
  logo?: string;
  primary_color?: string;
  secondary_color?: string;
  assocaf?: boolean;
  fifth_assignment?: boolean;
  tfr?: boolean;
  agencies?: Ente[];
  realm?: string;
};

export class CompanyClass implements Company {
  id = 0;
  name = '';
  vat_number = '';
  phone_number = '';
  user_ids = [];
  address = '';
  postal_code = '';
  region_id = 0;
  province_id = 0;
  city_id = 0;
  country = '';
  email = '';
  agency_ids = [];
  contract_ids = [];
  bank_details = '';
  logo = '';
  primary_color = '';
  secondary_color = '';
  assocaf = false;
  fifth_assignment = false;
  tfr = false;
  agencies = [];
  realm = '';
}
