import {
  COLORS,
  FONTS,
  DefaultAccordionSummaryTypography,
  LBTAccordion,
  LBTTitle,
  LBTSubtitle,
} from '@laborability/components';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ChangeScrollColor } from '../../components/ChangeScrollColor';
import Section from '../../components/Section';
import { SolaryFits } from '../../components/SolaryFits';
import { StaticArticles } from '../../components/StaticArticles';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxFive,
  AnswerBoxFour,
  AnswerBoxOne,
  AnswerBoxSeven,
  AnswerBoxSix,
  AnswerBoxThree,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationFirstBoxes';
import { SpaceTop } from '../../components/answerBoxes/Space';
import { navMenuState } from '../../recoil/states/global';
import { MENU_HEIGHT } from '../Home';
import { ARTICLES } from './Fifth';
import { useBreakpoint } from '@laborability/commons';
import useTrackPage from '../../hooks/useTrackPage';

const ACCORDION_ITEMS = [
  {
    question: 'Quali somme si possono cedere?',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Quali requisiti deve avere il lavoratore?',
    answer: <AnswerBoxTwo />,
  },
  {
    question: 'Chi non può cedere il quinto dello stipendio?',
    answer: <AnswerBoxThree />,
  },
  {
    question:
      'Quali sono i limiti di durata della cessione del quinto dello stipendio?',
    answer: <AnswerBoxFour />,
  },
  {
    question: 'Cosa deve fare il datore di lavoro?',
    answer: <AnswerBoxFive />,
  },
  {
    question:
      'Può essere stipulata una seconda cessione del quinto dello stipendio?',
    answer: <AnswerBoxSix />,
  },
  {
    question: 'Cosa succede se lo stipendio è oggetto anche di pignoramento?',
    answer: <AnswerBoxSeven />,
  },
];

const Fifth01 = () => {
  const { isDesktop } = useBreakpoint();
  const setNavColor = useSetRecoilState(navMenuState);

  useEffect(() => {
    setNavColor(COLORS.getInstance().PRIMARY_SUPERLIGHT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackPage(
    'Cessione del quinto - Articolo 1',
    '/app/cessione-quinto/articol-1',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().PRIMARY_SUPERLIGHT}>
        <ChangeScrollColor
          color={COLORS.getInstance().PRIMARY_SUPERLIGHT}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>Cos'è la cessione del quinto dello stipendio?</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTSubtitle>
          Il lavoratore può cedere una quota del proprio stipendio per ripagare
          un prestito. Quali requisiti sono necessari? Ci sono dei limiti di
          durata della cessione?
        </LBTSubtitle>
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        {ARTICLES[0].image}

        <Typography
          fontFamily={FONTS.SOURCE_SANS_PRO}
          fontSize={'16px'}
          fontStyle={'normal'}
          fontWeight={'400'}
          lineHeight={'22px'}
          paddingBottom={'16px'}
          paddingTop={'48px'}
          maxWidth={'680px'}
        >
          La cessione del quinto dello stipendio è un modo con cui un lavoratore{' '}
          <BoldSpan>estingue un proprio debito</BoldSpan>. Ciò avviene quando il
          lavoratore chiede un{' '}
          <BoldSpan>prestito a un istituto finanziario</BoldSpan>, offrendo di
          ripagarlo attraverso una{' '}
          <BoldSpan>quota fissa dello stipendio</BoldSpan> dovutogli dal datore
          di lavoro.
        </Typography>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            uniqueKey={`${index}`}
            details={item.answer}
            summary={
              <DefaultAccordionSummaryTypography children={item.question} />
            }
          ></LBTAccordion>
        ))}
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <ChangeScrollColor
          color={COLORS.getInstance().WHITE}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>Vai ai prossimi articoli</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <StaticArticles toExclude={[0]}></StaticArticles>
      </Section>
    </>
  );
};

export default Fifth01;
