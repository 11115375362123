import { RecoilState } from 'recoil';
import { snackBarState } from './state';

export function setSnackbarError(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: ((currVal: T) => T) | T,
  ) => void,
  error: any,
) {
  set(snackBarState, {
    show: true,
    message:
      'Attenzione, errore: ' +
      error?.message +
      ' ' +
      error?.response?.data?.message,
    severity: 'error',
    duration: 5000,
    code: error?.response?.status,
  });
}

export function setSnackbarSuccess(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: ((currVal: T) => T) | T,
  ) => void,
  message: string,
  code: number,
) {
  set(snackBarState, {
    show: true,
    message,
    severity: 'success',
    duration: 5000,
    code,
  });
}
