import { FONTS } from '@laborability/components';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

export type StyledAnswerBoxProps = {
  children: React.ReactNode;
};

const StyledAnswerBox = styled(Box)<{}>(() => ({}));

const AnswerBox: React.FC<StyledAnswerBoxProps> = ({
  children,
}: StyledAnswerBoxProps) => {
  return (
    <StyledAnswerBox>
      <Typography
        fontFamily={FONTS.SOURCE_SANS_PRO}
        fontSize={'16px'}
        fontStyle={'normal'}
        fontWeight={'400'}
        lineHeight={'22px'}
        paddingBottom={'16px'}
        maxWidth={'680px'}
      >
        {children}
      </Typography>
    </StyledAnswerBox>
  );
};

export default AnswerBox;
