import Switch, { SwitchProps } from '@mui/material/Switch';

import styled from 'styled-components';

export type LBTSwitchProps = SwitchProps & {
  datatestid?: string;
};

const StyledSwitch = styled(Switch)<
  SwitchProps & {
    datatestid?: string;
    'data-track': string;
  }
>(({ theme, datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
}));

const LBTSwitch: React.FC<LBTSwitchProps> = ({
  datatestid = '',
  ...props
}: LBTSwitchProps) => {
  return (
    <StyledSwitch
      data-track={datatestid}
      datatestid={`lbt-switch-${datatestid}`}
      {...props}
    />
  );
};

export default LBTSwitch;
