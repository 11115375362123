import { getUUID } from '@laborability/commons';
import { COLORS } from '@laborability/components';
import React from 'react';
import { atom } from 'recoil';

export const navMenuState = atom<string>({
  key: getUUID(),
  default: COLORS.getInstance().WHITE,
});

export const swipeableDrawerState = atom<{
  open: boolean;
  title: string;
  description: string;
  component: React.ReactNode | null;
}>({
  key: getUUID(),
  default: {
    open: false,
    title: '',
    description: '',
    component: null,
  },
});
