import { Typography, TypographyProps } from '@mui/material';
import { COLORS, FONTS } from '../../utils';
import { useBreakpoint } from '@laborability/commons';
import { ReactNode } from 'react';

export type LBTSubtitleProps = TypographyProps & {
  children?: ReactNode;
  color?: string;
};
const LBTSubtitle: React.FC<LBTSubtitleProps> = ({
  children,
  color = COLORS.getInstance().BW_GREYS_JET_BLACK,
  ...props
}: LBTSubtitleProps) => {
  const { isDesktop } = useBreakpoint();
  return (
    <Typography
      variant="h2"
      fontWeight={500}
      maxWidth={'680px'}
      fontSize={isDesktop ? '20px' : '17px'}
      lineHeight={isDesktop ? '30px' : '24px'}
      textAlign="center"
      children={children}
      color={color}
      fontFamily={FONTS.SPACE_GROTESK}
      {...props}
    />
  );
};

export default LBTSubtitle;
