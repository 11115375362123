import { Chip, ChipProps, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { FONTS } from '../../utils';

const StyledChip = styled(Chip)<
  ChipProps & { datatestid: string; 'data-track': string }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
  '.MuiChip-label': {
    padding: '4px 8px',
  },
  '&.MuiChip-root > svg:first-child': {
    paddingLeft: '8px',
  },
  '&.MuiChip-root > :last-child': {
    //  svg:last-child':
    paddingRight: '8px',
  },
}));

export type LBTChipProps = ChipProps & { datatestid?: string };

const LBTChip: React.FC<LBTChipProps> = ({
  size,
  icon,
  deleteIcon,
  label,
  onDelete,
  datatestid = '',
  ...props
}: LBTChipProps) => {
  const isSmall = size === 'small';

  return (
    <StyledChip
      datatestid={`lbt-chip-${datatestid}`}
      data-track={datatestid}
      label={label}
      size={size}
      icon={isSmall ? undefined : icon}
      deleteIcon={isSmall ? undefined : deleteIcon}
      onDelete={isSmall ? undefined : onDelete}
      {...props}
    />
  );
};

export default LBTChip;
