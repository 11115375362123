import {
  COLORS,
  FONTS,
  DefaultAccordionSummaryTypography,
  LBTAccordion,
  LBTTitle,
  LBTSubtitle,
} from '@laborability/components';
import { Typography } from '@mui/material';
import Section from '../../components/Section';
import { StaticArticles } from '../../components/StaticArticles';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ChangeScrollColor } from '../../components/ChangeScrollColor';
import { SolaryFits } from '../../components/SolaryFits';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxFour,
  AnswerBoxOne,
  AnswerBoxThree,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationThirdBoxes';
import { SpaceTop } from '../../components/answerBoxes/Space';
import { navMenuState } from '../../recoil/states/global';
import { MENU_HEIGHT } from '../Home';
import { ARTICLES } from './Fifth';
import { useBreakpoint } from '@laborability/commons';
import useTrackPage from '../../hooks/useTrackPage';

const ACCORDION_ITEMS = [
  {
    question: 'Cessione del quinto, cos’è',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Qual è il limite per la cessione del quinto?',
    answer: <AnswerBoxTwo />,
  },
  {
    question: 'Si applica anche alla tredicesima mensilità?',
    answer: <AnswerBoxThree />,
  },
  {
    question: 'Si può cedere più di un quinto?',
    answer: <AnswerBoxFour />,
  },
];

const Fifth03 = () => {
  const { isDesktop } = useBreakpoint();
  const setNavColor = useSetRecoilState(navMenuState);

  useEffect(() => {
    setNavColor(COLORS.getInstance().WHITE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackPage(
    'Cessione del quinto - Articolo 3',
    '/app/cessione-quinto/articol-3',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <ChangeScrollColor
          color={COLORS.getInstance().WHITE}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle color={COLORS.getInstance().BERRY_POPSICLE_MEDIUM}>
          Qual è il limite massimo per la cessione del quinto?
        </LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTSubtitle>
          La cessione del quinto incontra dei precisi limiti imposti dalla legge
          che non possono essere superati
        </LBTSubtitle>
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        {ARTICLES[2].image}
        <Typography
          fontFamily={FONTS.SOURCE_SANS_PRO}
          fontSize={'16px'}
          fontStyle={'normal'}
          fontWeight={'400'}
          lineHeight={'22px'}
          paddingBottom={'16px'}
          paddingTop={'48px'}
          maxWidth={'680px'}
        >
          La cessione dello stipendio{' '}
          <BoldSpan>non può mai superare il quinto della busta paga</BoldSpan>.
          È un limite inderogabile: la ratio della norma è consentire al
          lavoratore di finanziarsi e di pagare un prestito senza però
          compromettere la propria situazione finanziaria al di sotto di una
          determinata soglia.
        </Typography>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            uniqueKey={`${index}`}
            details={item.answer}
            summary={
              <DefaultAccordionSummaryTypography children={item.question} />
            }
          ></LBTAccordion>
        ))}
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <ChangeScrollColor
          color={COLORS.getInstance().WHITE}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>Vai ai prossimi articoli</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <StaticArticles toExclude={[2]}></StaticArticles>
      </Section>
    </>
  );
};

export default Fifth03;
