import Box, { BoxProps } from '@mui/material/Box';
import React from 'react';
import styled from 'styled-components';
import LBTLabel from '../Label';
import { COLORS, FONTS } from '../../utils';
import { getUUID } from '@laborability/commons';

const ID = getUUID();

const StyledButtonIllustrative = styled(Box)<
  BoxProps & { datatestid: string; 'data-track': string }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
  backgroundColor: COLORS.getInstance().WHITE,
  textAlign: 'center',
  borderRadius: '10px',
  outline: `1px solid ${COLORS.getInstance().PRIMARY_MAIN}`,
  padding: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: `${COLORS.getInstance().PRIMARY_IPERLIGHT} !important`,
    outline: `1px solid ${COLORS.getInstance().PRIMARY_DARK}`,
    boxShadow: 'none !important',
  },

  '&:active': {
    boxShadow:
      '0px 3px 5px -1px #0000001A, 0px 6px 10px 0px #0000001A, 0px 1px 18px 0px #0000001A',
    outline: `2px solid ${COLORS.getInstance().PRIMARY_DARK}`,
  },
  '&:focus': {
    boxShadow:
      '0px 3px 5px -1px #0000001A, 0px 6px 10px 0px #0000001A, 0px 1px 18px 0px #0000001A',
    outline: `2px solid ${COLORS.getInstance().PRIMARY_DARK}`,
  },
}));

const LBTButtonIllustrative: React.FC<{
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
  datatestid: string;
}> = ({
  onClick,
  icon,
  label,
  datatestid,
}: {
  onClick: () => void;
  icon: React.ReactNode | string;
  label: string;
  datatestid: string;
}) => {
  return (
    <StyledButtonIllustrative
      datatestid={`lbt-button-illustrative-${datatestid}`}
      data-track={datatestid}
      onClick={onClick}
      className={`btnillustrative-${ID}`}
    >
      <>
        {typeof icon === 'string' && (
          <img data-track={datatestid} src={icon} alt={label}></img>
        )}
        {typeof icon !== 'string' && icon}
      </>

      <LBTLabel
        fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
        fontSize={'16px'}
        lineHeight={'18px'}
        letterSpacing={'0.32px'}
        marginTop={'11px'}
        color={COLORS.getInstance().PRIMARY_MAIN}
        datatestid={datatestid}
      >
        {label}
      </LBTLabel>
    </StyledButtonIllustrative>
  );
};

export default LBTButtonIllustrative;
