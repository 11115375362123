import { Misura } from '@laborability/commons';
import {
  COLORS,
  IconCalendarComponent,
  IconEnteComponent,
  IconsSize,
  IconsStyle,
  LBTLabel,
} from '@laborability/components';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  styled,
} from '@mui/material';

const StyledListIcon = styled(ListItemIcon)<
  ListItemIconProps & { marginTop?: string; justifyContent?: string }
>(({ marginTop, justifyContent }) => ({
  marginTop: marginTop,
  justifyContent: justifyContent,
}));

const BonusListVariantRight: React.FC<{
  misura?: Misura;
  isDesktop: boolean;
}> = ({ misura, isDesktop }) => {
  const dayjs = require('dayjs');
  var customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);

  const startDate = dayjs(misura?.start_date, 'DD-MM-YYYY HH:mm:SS').format(
    'DD/MM/YYYY',
  );
  const endDate = dayjs(misura?.end_date, 'DD-MM-YYYY HH:mm:SS').format(
    'DD/MM/YYYY',
  );

  return (
    <List
      disablePadding
      sx={{
        order: isDesktop ? 1 : 3,
        marginTop: isDesktop ? 0 : '16px',
      }}
    >
      <ListItem disablePadding alignItems="flex-start">
        <StyledListIcon>
          <IconCalendarComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        </StyledListIcon>
        <ListItemText
          sx={{ padding: '8px 0px', margin: '0px' }}
          primary={
            <LBTLabel
              textAlign="left"
              fontSize="16px"
              lineHeight="24px"
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            >
              {`Dal ${startDate} al ${endDate}`}
            </LBTLabel>
          }
          secondary={
            <LBTLabel
              color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
              textAlign="left"
              fontSize="14px"
              lineHeight="16.8px"
            >
              Il bonus è valido in questo periodo
            </LBTLabel>
          }
        />
      </ListItem>

      <ListItem disablePadding alignItems="flex-start">
        <StyledListIcon>
          <IconEnteComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        </StyledListIcon>
        <ListItemText
          sx={{ padding: '8px 0px', margin: '0px' }}
          primary={
            <LBTLabel
              textAlign="left"
              fontSize="16px"
              lineHeight="24px"
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            >
              {misura?.agency?.name ?? ''}
            </LBTLabel>
          }
          secondary={
            <LBTLabel
              color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
              textAlign="left"
              fontSize="14px"
              lineHeight="16.8px"
            >{`Il bonus è gestito da un ente ${misura?.agency?.is_public_agency ? 'pubblico' : 'privato'}`}</LBTLabel>
          }
        />
      </ListItem>
    </List>
  );
};

export default BonusListVariantRight;
