import { useBreakpoint } from '@laborability/commons';
import {
  LBTTitle,
  LBTProgressSpinner,
  LBTLabel,
  LBTSubtitle,
  COLORS,
} from '@laborability/components';
import { SpaceTop } from './answerBoxes/Space';
import Section from './Section';

export default function Loader() {
  const { isDesktop } = useBreakpoint();
  return (
    <Section
      backgroundColor={COLORS.getInstance().WHITE}
      style={{ display: 'flex', flex: 1 }}
    >
      <SpaceTop margin="64px" />
      <LBTTitle>Manca davvero poco</LBTTitle>
      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
      <LBTSubtitle>Lasciamo che la tecnologia faccia la sua magia</LBTSubtitle>
      <SpaceTop margin={isDesktop ? '64px' : '48px'} />
      <LBTProgressSpinner />
      <SpaceTop margin={isDesktop ? '64px' : '48px'} />
      <LBTLabel fontSize="14px">
        A breve potrai accedere alla piattaforma ed esplorare più di 200 bonus,
        incentivi e agevolazioni fiscali
      </LBTLabel>
    </Section>
  );
}
