export enum CheckboxLabelPosition {
  RIGHT = 'row-reverse',
  LEFT = 'row',
}

export enum CheckboxStyle {
  PRIMARY = 'primary',
  NEGATIVE = 'negative',
}

export enum CheckboxType {
  UNCHECKED = 'unchecked',
  CHECKED = 'checked',
  INTERMEDIATE = 'intermediate',
}
