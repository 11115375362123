import { createHashRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import { COLORS } from '@laborability/components';
import Home from './pages/Home';
import NoPage404 from './pages/NoPage404';
import CategoryPage from './pages/dynamic/CategoryPage';
import TagPage from './pages/dynamic/TagPage';
import SearchMisure from './pages/dynamic/SearchMisure';
import Fifth from './pages/statics/Fifth';
import Fifth01 from './pages/statics/Fifth01';
import Fifth02 from './pages/statics/Fifth02';
import Fifth03 from './pages/statics/Fifth03';
import Fifth04 from './pages/statics/Fifth04';
import Fifth05 from './pages/statics/Fifth05';
import Fifth06 from './pages/statics/Fifth06';
import SeverancePay from './pages/statics/SeverancePay';
import MeasureDetailPage from './pages/dynamic/MeasureDetailPage';
import { currentCompanyConfigState } from '@laborability/commons';
import { useRecoilValue } from 'recoil';
import { CookiePolicy, PrivacyPolicy } from './pages';

export const APP_ROUTES = {
  HOME: 'home',
  FIFTH: 'fifth',
  FIFTH_01: 'fifth_01',
  FIFTH_02: 'fifth_02',
  FIFTH_03: 'fifth_03',
  FIFTH_04: 'fifth_04',
  FIFTH_05: 'fifth_05',
  FIFTH_06: 'fifth_06',
  SEVERANCE_PAY: 'severance-pay',
  CATEGORY: 'category',
  MEASURE_DETAIL: 'measure-detail',
  TAG: 'tag',
  SEARCH_MISURE: 'search-misure',
  COOKIE_POLICY: 'cookie-policy',
  PRIVACY_POLICY: 'privacy-policy',
};

export default function AppRoutes() {
  const { fifth_assignment, tfr } = useRecoilValue(currentCompanyConfigState);
  const fifthAssignmentRoutes = [
    {
      path: APP_ROUTES.FIFTH,
      element: <Fifth />,
      handle: {
        pageColor: COLORS.getInstance().WHITE,
        page_title: 'Cessione del quinto',
      },
    },
    {
      path: APP_ROUTES.FIFTH_01,
      element: <Fifth01 />,
      handle: {
        pageColor: COLORS.getInstance().WHITE,
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_02,
      element: <Fifth02 />,
      handle: {
        pageColor: COLORS.getInstance().WHITE,
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_03,
      element: <Fifth03 />,
      handle: {
        pageColor: COLORS.getInstance().WHITE,
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_04,
      element: <Fifth04 />,
      handle: {
        pageColor: COLORS.getInstance().WHITE,
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_05,
      element: <Fifth05 />,
      handle: {
        pageColor: COLORS.getInstance().WHITE,
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_06,
      element: <Fifth06 />,
      handle: { pageColor: COLORS.getInstance().WHITE },
    },
  ];
  const severancePayRoutes = [
    {
      path: APP_ROUTES.SEVERANCE_PAY,
      element: <SeverancePay />,
      handle: { pageColor: COLORS.getInstance().WHITE },
    },
  ];
  const router = createHashRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
          handle: { pageColor: COLORS.getInstance().PRIMARY_MAIN },
        },
        {
          path: APP_ROUTES.HOME,
          element: <Home />,
          handle: {
            pageColor: COLORS.getInstance().PRIMARY_MAIN,
            page_title: 'Home page',
          },
        },
        {
          path: `${APP_ROUTES.CATEGORY}/:id`,
          element: <CategoryPage />,
          handle: { pageColor: COLORS.getInstance().WHITE },
        },
        {
          path: `${APP_ROUTES.MEASURE_DETAIL}/:id`,
          element: <MeasureDetailPage />,
          handle: { pageColor: COLORS.getInstance().PRIMARY_SUPERLIGHT },
        },
        {
          path: `${APP_ROUTES.TAG}/:id`,
          element: <TagPage />,
          handle: { pageColor: COLORS.getInstance().WHITE },
        },
        {
          path: APP_ROUTES.SEARCH_MISURE,
          element: <SearchMisure />,
          handle: { pageColor: COLORS.getInstance().WHITE },
        },
        {
          path: APP_ROUTES.PRIVACY_POLICY,
          element: <PrivacyPolicy />,
          handle: { pageColor: COLORS.getInstance().WHITE },
        },
        {
          path: APP_ROUTES.COOKIE_POLICY,
          element: <CookiePolicy />,
          handle: { pageColor: COLORS.getInstance().WHITE },
        },
        ...(tfr ? severancePayRoutes : []),
        ...(fifth_assignment ? fifthAssignmentRoutes : []),
        {
          path: '*',
          element: <NoPage404 />,
        },
      ],
    },
    {
      path: '/*',
      element: <NoPage404 />,
    },
  ]);

  return <RouterProvider router={router} />;
}
