import { KeycloakContext } from '@laborability/components';
import { useEffect } from 'react';
import './App.css';
import { initializeTagManager } from './utils/gtm';
import AppRoutes from './Routes';
import Loader from './components/Loader';
import { useKeycloak, useDayjs, useStartup } from '@laborability/commons';
import TagManager from 'react-gtm-module';
import md5 from 'md5';

function App() {
  useDayjs();
  const { keycloak, init } = useKeycloak();
  const { onStartup, isCompletedStartup } = useStartup();

  useEffect(() => {
    initializeTagManager();
    init();
  }, []);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      onStartup();

      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          user: md5(keycloak?.tokenParsed?.preferred_username),
          timestamp: new Date().toISOString(),
        },
      });
      console.log('@@@tagManager', {
        event: 'login',
        user: md5(keycloak?.tokenParsed?.preferred_username),
        timestamp: new Date().toISOString(),
      });
    }
  }, [keycloak?.tokenParsed]);

  return (
    <KeycloakContext.Provider value={{ keycloak }}>
      {isCompletedStartup ? <AppRoutes /> : <Loader />}
    </KeycloakContext.Provider>
  );
}

export default App;
