const ImageLinkedinComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_2812_13625)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.50115 4.45855C6.50115 2.62769 5.47258 1.59912 3.64001 1.59912C1.81086 1.59912 0.780579 2.62769 0.780579 4.45855C0.780579 6.28941 1.80915 7.31969 3.64001 7.31969C5.47086 7.31969 6.50286 6.28769 6.50286 4.45855H6.50115ZM3.64001 9.59284C5.09029 9.59284 6.26629 10.7688 6.26629 12.2191V21.2191C6.27525 21.5695 6.21396 21.9182 6.08605 22.2446C5.95813 22.5709 5.76618 22.8684 5.5215 23.1194C5.27682 23.3704 4.98437 23.5699 4.66138 23.7061C4.33839 23.8423 3.9914 23.9125 3.64086 23.9125C3.29033 23.9125 2.94334 23.8423 2.62035 23.7061C2.29736 23.5699 2.00491 23.3704 1.76023 23.1194C1.51555 22.8684 1.32359 22.5709 1.19568 22.2446C1.06777 21.9182 1.00648 21.5695 1.01544 21.2191V12.2191C1.01544 10.7688 2.18972 9.59284 3.64001 9.59284ZM16.0549 14.8437C15.0194 14.8437 14.1794 15.6837 14.1794 16.7191V21.2208C14.1884 21.5713 14.1271 21.9199 13.9992 22.2463C13.8713 22.5727 13.6793 22.8701 13.4346 23.1211C13.19 23.3721 12.8975 23.5716 12.5745 23.7078C12.2515 23.844 11.9045 23.9142 11.554 23.9142C11.2035 23.9142 10.8565 23.844 10.5335 23.7078C10.2105 23.5716 9.91805 23.3721 9.67337 23.1211C9.42869 22.8701 9.23674 22.5727 9.10882 22.2463C8.98091 21.9199 8.91962 21.5713 8.92858 21.2208V16.7191C8.92858 14.8291 9.67938 13.0165 11.0158 11.6801C12.3523 10.3436 14.1649 9.59284 16.0549 9.59284C17.9449 9.59284 19.7575 10.3436 21.0939 11.6801C22.4303 13.0165 23.1812 14.8291 23.1812 16.7191V21.2208C23.1901 21.5713 23.1288 21.9199 23.0009 22.2463C22.873 22.5727 22.681 22.8701 22.4364 23.1211C22.1917 23.3721 21.8992 23.5716 21.5762 23.7078C21.2532 23.844 20.9063 23.9142 20.5557 23.9142C20.2052 23.9142 19.8582 23.844 19.5352 23.7078C19.2122 23.5716 18.9198 23.3721 18.6751 23.1211C18.4304 22.8701 18.2385 22.5727 18.1105 22.2463C17.9826 21.9199 17.9213 21.5713 17.9303 21.2208V16.7191C17.9303 15.6837 17.0903 14.8437 16.0549 14.8437Z"
          fill="#80ABF9"
        />
      </g>
      <defs>
        <clipPath id="clip0_2812_13625">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.142883 0.801758)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImageLinkedinComponent;
