import { COLORS, FONTS } from '@laborability/components';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ARTICLES } from '../pages/statics/Fifth';
import { StaticGrid } from './StaticGrid';
import { useBreakpoint } from '@laborability/commons';

export const StaticArticles: React.FC<{ toExclude: Array<number> }> = ({
  toExclude,
}) => {
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();
  // const [sendEventToGA4] = useGA4();
  return (
    <>
      {isDesktop &&
        ARTICLES.filter(art => !toExclude.includes(art.index)).map(
          (article, index) => (
            <Box key={index}>
              <>
                <Stack
                  style={{ maxWidth: '680px', cursor: 'pointer' }}
                  direction={'row'}
                  onClick={() => {
                    // sendEventToGA4(GA4_EVENT_ACTION.CLICK_ITEM, {
                    //   item: GA4_PAGE_BLOCK.STATIC_ITEM,
                    //   path: article.path,
                    //   title: article.title,
                    // });
                    navigate('/' + article.path);
                  }}
                >
                  <Box sx={{ marginRight: '24px' }}>
                    <Typography
                      fontFamily={FONTS.SPACE_GROTESK}
                      fontSize={'25px'}
                      fontStyle={'normal'}
                      fontWeight={'900'}
                      lineHeight={'30px'}
                      paddingBottom={'16px'}
                      sx={{
                        '&:hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      {article.title}
                    </Typography>

                    <Typography
                      fontFamily={FONTS.SOURCE_SANS_PRO}
                      fontSize={'14px'}
                      fontStyle={'normal'}
                      fontWeight={'400'}
                      lineHeight={'20px'}
                      marginTop={'16px'}
                    >
                      {article.body}
                    </Typography>
                  </Box>
                  {article.image && (
                    <Box sx={{ minWidth: '240px' }}>{article.image}</Box>
                  )}
                </Stack>
                <Divider
                  sx={{
                    borderColor: COLORS.getInstance().BW_GREYS_JET_BLACK,
                    margin: '32px 0px',
                    opacity: '15%',
                    borderTop: '1px;',
                  }}
                  orientation="horizontal"
                  flexItem
                />
              </>
            </Box>
          ),
        )}

      {!isDesktop && (
        <StaticGrid
          articles={ARTICLES.filter(art => !toExclude.includes(art.index))}
        />
      )}
    </>
  );
};
