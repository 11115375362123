import {
  AdvancedSearchMisura,
  advancedSearchMisura,
  benefitsState,
  regionsState,
} from '@laborability/commons';
import {
  LBTSelect,
  LBTLabel,
  LBTSwitch,
  LBTButton,
  LBTModal,
} from '@laborability/components';
import { COLORS } from '@laborability/components/src/utils/Colors';
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useTrackPage from '../hooks/useTrackPage';

interface Props {
  open: boolean;
  onClose: () => void;
  filters: AdvancedSearchMisura;
  setFilters: (params: AdvancedSearchMisura) => void;
  isDesktop: boolean;
}

export default function ModalRicerca({
  open,
  onClose,
  filters,
  setFilters,
  isDesktop,
}: Props) {
  const regions = useRecoilValue(regionsState);
  const benefits = useRecoilValue(benefitsState);
  const [modalFilter, setModalFilter] = useState<AdvancedSearchMisura>(filters);
  const [resultsNumber, setResultsNumber] = useState<number | undefined>(
    undefined,
  );

  const getTotalResults = async () => {
    try {
      const { sub_categories, ...rest } = modalFilter;
      const res = await advancedSearchMisura({ ...rest, limit: 0 });
      if (!res || !res.data) return;
      setResultsNumber(res?.data?.total ?? 0);
    } catch (e) {}
  };

  const getFiltroTerritorio = () => {
    if (modalFilter.national) return 'nazionale';
    if (modalFilter.regional) return 'regionale';
    return undefined;
  };

  useEffect(() => {
    getTotalResults();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  useEffect(() => {
    setModalFilter(filters);
  }, [filters]);

  useTrackPage(open ? 'Ricerca avanzata' : '', open ? '/ricerca-avanzata' : '');

  return (
    <LBTModal
      open={open}
      onXClose={onClose}
      title="Ricerca avanzata"
      isDesktop={isDesktop}
    >
      <Grid container spacing="24px" style={{ padding: '10px 0' }}>
        <Grid item mobile={12} desktop={6}>
          <LBTSelect
            datatestid="advanced_search_list_territorio"
            variant="outlined"
            label="Territorio"
            items={[
              { id: 'nazionale', name: 'Nazionale' },
              { id: 'regionale', name: 'Regionale' },
            ]}
            value={getFiltroTerritorio()}
            handleChange={(e: any) => {
              if (e === 'regionale')
                return setModalFilter({
                  ...modalFilter,
                  regional: true,
                  national: undefined,
                });
              if (e === 'nazionale')
                return setModalFilter({
                  ...modalFilter,
                  region_id: [],
                  regional: undefined,
                  national: true,
                });
              return setModalFilter({
                ...modalFilter,
                region_id: [],
                regional: undefined,
                national: undefined,
              });
            }}
            helperText="Seleziona bonus nazionali o bonus regionali"
          />
        </Grid>
        {(modalFilter.regional || isDesktop) && (
          <Grid item mobile={12} desktop={6}>
            <LBTSelect
              variant="outlined"
              label="Regione"
              items={regions}
              disabled={!modalFilter.regional}
              multiple
              value={modalFilter.region_id}
              handleChange={(e: any) => {
                setModalFilter({ ...modalFilter, region_id: e });
              }}
            />
          </Grid>
        )}
        <Grid item mobile={12} desktop={6}>
          <LBTSelect
            datatestid="advanced_search_list_modalita_riscossione"
            variant="outlined"
            label="Modalità di riscossione"
            items={benefits as any}
            multiple
            value={modalFilter.benefit_type_id}
            handleChange={(e: any) => {
              setModalFilter({ ...modalFilter, benefit_type_id: e });
            }}
            helperText="Seleziona una o più modalità di riscossione dei bonus"
          />
        </Grid>
        <Grid item mobile={12} desktop={6}>
          <LBTSelect
            datatestid="advanced_search_list_status"
            variant="outlined"
            label="Status"
            items={[
              {
                id: 'upcoming',
                name: 'In arrivo',
                description: 'Presto potrai fare domanda per il bonus',
              },
              {
                id: 'news',
                name: 'Disponibile',
                description: 'Puoi già fare domanda per il bonus',
              },
              {
                id: 'expiring_soon',
                name: 'In scadenza',
                description:
                  'Manca poco alla scadenza della domanda di presentazione',
              },
            ]}
            multiple
            value={[
              ...(modalFilter.news ? ['news'] : []),
              ...(modalFilter.upcoming ? ['upcoming'] : []),
              ...(modalFilter.expiring_soon ? ['expiring_soon'] : []),
            ]}
            handleChange={(e: any) => {
              setModalFilter({
                ...modalFilter,
                news: e.find((item: any) => item === 'news') ? true : undefined,
                upcoming: e.find((item: any) => item === 'upcoming')
                  ? true
                  : undefined,
                expiring_soon: e.find((item: any) => item === 'expiring_soon')
                  ? true
                  : undefined,
              });
            }}
            helperText="Seleziona in base allo status dei bonus: in arrivo, disponibile e in scadenza. Puoi selezionare più di un’opzione"
          />
        </Grid>
        <Grid item mobile={12} desktop={6}>
          <Stack direction="row" justifyContent="space-between">
            <Stack alignItems="start">
              <LBTLabel
                fontSize="16px"
                textAlign="start"
                datatestid="advanced_search_list_no_isee"
              >
                Per tutti i redditi
              </LBTLabel>
              <LBTLabel
                fontSize="14px"
                textAlign="start"
                color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
                datatestid="advanced_search_list_no_isee"
              >
                Seleziona questa opzione per filtrare i bonus per cui non è
                necessario presentare l'ISEE
              </LBTLabel>
            </Stack>
            <LBTSwitch
              datatestid="advanced_search_list_no_isee"
              checked={modalFilter.for_all_incomes}
              onChange={e =>
                setModalFilter({
                  ...modalFilter,
                  for_all_incomes: e.target.checked ? true : undefined,
                })
              }
            />
          </Stack>
        </Grid>
        <Grid item mobile={12} desktop={6}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <LBTLabel
                fontSize="16px"
                textAlign="start"
                datatestid="advanced_search_list_domanda_remoto"
              >
                Domanda online o al telefono
              </LBTLabel>
              <LBTLabel
                fontSize="14px"
                textAlign="start"
                color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
                datatestid="advanced_search_list_domanda_remoto"
              >
                Seleziona questa opzione per filtrare i bonus che puoi
                richiedere online o al telefono, senza bisogno di andare allo
                sportello
              </LBTLabel>
            </Stack>
            <LBTSwitch
              datatestid="advanced_search_list_domanda_remoto"
              checked={modalFilter.question_online_or_phone}
              onChange={e =>
                setModalFilter({
                  ...modalFilter,
                  question_online_or_phone: e.target.checked ? true : undefined,
                })
              }
            />
          </Stack>
        </Grid>
        <Grid item mobile={12} desktop={6} />
        <Grid item mobile={12} desktop={6}>
          <Stack
            direction={isDesktop ? 'row' : 'column-reverse'}
            spacing="16px"
          >
            <LBTButton variant="invisible" fullWidth onClick={onClose}>
              Annulla
            </LBTButton>
            <LBTButton
              datatestid="advanced_search_show_results"
              variant="contained"
              disabled={!resultsNumber}
              fullWidth
              onClick={() => {
                setFilters(modalFilter);
                onClose();
              }}
            >
              {resultsNumber !== undefined
                ? `Mostra ${resultsNumber} risultati`
                : 'Applica'}
            </LBTButton>
          </Stack>
        </Grid>
      </Grid>
    </LBTModal>
  );
}
