import {
  COLORS,
  FONTS,
  LBTButton,
  LBTSubtitle,
  LBTTitle,
} from '@laborability/components';
import { Box, Typography } from '@mui/material';
import LogoSF from '../assets/images/logo-sf.png';
import { MENU_HEIGHT } from '../pages/Home';
import { Image } from '../pages/statics/Fifth';
import { ChangeScrollColor } from './ChangeScrollColor';
import Section from './Section';
import { SpaceTop } from './answerBoxes/Space';
import { useBreakpoint } from '@laborability/commons';

export const SolaryFits = () => {
  const { isDesktop } = useBreakpoint();
  return (
    <Section backgroundColor={COLORS.getInstance().MINT_TONIC_IPERLIGHT}>
      <ChangeScrollColor
        color={COLORS.getInstance().MINT_TONIC_IPERLIGHT}
        changeBreakpoint={MENU_HEIGHT}
      />
      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
      <LBTTitle>Vuoi approfittare della cessione del quinto?</LBTTitle>
      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
      <LBTSubtitle>
        Attraverso la partnership con SalaryFits, avrai a disposizione un
        supporto esclusivo per capire come poter sfruttarlo al meglio!
      </LBTSubtitle>
      <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      <Box sx={{ 'max-width': '250px' }}>
        <Image src={LogoSF} />
      </Box>

      <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      <Typography
        color={COLORS.getInstance().BW_GREYS_JET_BLACK}
        children="Descrizione del partner ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque"
        fontFamily={FONTS.SOURCE_SANS_PRO}
        fontSize={'14px'}
        fontStyle={'normal'}
        fontWeight={'400'}
        lineHeight={'20px'}
        align={'center'}
      ></Typography>
      <SpaceTop margin={'32px'} />
      <LBTButton
        datatestid="btn-salaryfits"
        color="primary-negative"
        variant="contained"
        children="Scopri il servizio"
        onClick={() => {
          console.log('TODO');
        }}
      ></LBTButton>
      <SpaceTop margin={isDesktop ? '48px' : '32px'} />
    </Section>
  );
};
