import {
  COLORS,
  DefaultAccordionSummaryTypography,
  FONTS,
  LBTAccordion,
  LBTSubtitle,
  LBTTitle,
} from '@laborability/components';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ChangeScrollColor } from '../../components/ChangeScrollColor';
import Section from '../../components/Section';
import { SolaryFits } from '../../components/SolaryFits';
import { StaticArticles } from '../../components/StaticArticles';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxOne,
  AnswerBoxThree,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationFourthBoxes';
import { SpaceTop } from '../../components/answerBoxes/Space';
import { navMenuState } from '../../recoil/states/global';
import { MENU_HEIGHT } from '../Home';
import { ARTICLES } from './Fifth';
import { useBreakpoint } from '@laborability/commons';
import useTrackPage from '../../hooks/useTrackPage';

const ACCORDION_ITEMS = [
  {
    question: 'Quali somme si possono cedere?',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Ci sono dei limiti',
    answer: <AnswerBoxTwo />,
  },
  {
    question: 'Qual è la procedura da seguire?',
    answer: <AnswerBoxThree />,
  },
];

const Fifth06 = () => {
  const { isDesktop } = useBreakpoint();
  const setNavColor = useSetRecoilState(navMenuState);

  useEffect(() => {
    setNavColor(COLORS.getInstance().LEMON_CAKE_SUPERLIGHT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackPage(
    'Cessione del quinto - Articolo 6',
    '/app/cessione-quinto/articol-6',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().LEMON_CAKE_SUPERLIGHT}>
        <ChangeScrollColor
          color={COLORS.getInstance().LEMON_CAKE_SUPERLIGHT}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>
          Cessione del quinto della pensione INPS, come funziona
        </LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTSubtitle>
          Il pensionato può cedere una quota della propria pensione per ripagare
          un prestito? Quali requisiti sono necessari? Ci sono dei limiti?
        </LBTSubtitle>
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        {ARTICLES[5].image}
        <Typography
          fontFamily={FONTS.SOURCE_SANS_PRO}
          fontSize={'16px'}
          fontStyle={'normal'}
          fontWeight={'400'}
          lineHeight={'22px'}
          paddingBottom={'16px'}
          paddingTop={'48px'}
          maxWidth={'680px'}
        >
          La <BoldSpan>cessione del quinto della pensione</BoldSpan> è un{' '}
          <BoldSpan>modo con cui un pensionato estingue un</BoldSpan> proprio{' '}
          <BoldSpan>debito</BoldSpan>. Ciò avviene quando il{' '}
          <BoldSpan>
            pensionato chiede un prestito a un istituto finanziario
          </BoldSpan>
          , offrendo di{' '}
          <BoldSpan>
            ripagarlo attraverso una quota fissa della pensione
          </BoldSpan>{' '}
          dovutagli dall'INPS.
        </Typography>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            uniqueKey={`${index}`}
            details={item.answer}
            summary={
              <DefaultAccordionSummaryTypography children={item.question} />
            }
          ></LBTAccordion>
        ))}
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <ChangeScrollColor
          color={COLORS.getInstance().WHITE}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>Vai ai prossimi articoli</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <StaticArticles toExclude={[5]}></StaticArticles>
      </Section>
    </>
  );
};

export default Fifth06;
