import { SnackBar } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const snackBarState = atom<SnackBar>({
  key: getUUID(),
  default: {
    show: false,
    severity: 'success',
    message: '',
    code: 0,
    duration: 5000,
  },
});
