import AnswerBox from './AnswerBox';
import BoldSpan from './BoldSpan';
import { SpaceTop } from './Space';
import AnswerBoxList from './StyledList';

const AnswerBoxOne: React.FC = () => {
  return (
    <AnswerBox>
      Si intende un'
      <BoldSpan>operazione di finanziamento</BoldSpan> con la quale un
      lavoratore ottiene un prestito da una banca o finanziaria e si impegna a
      pagare questo finanziamento tramite la cessione di parte del proprio
      stipendio.
      <SpaceTop />
      In questo modo la banca/finanziaria ottiene il pagamento delle rate
      mediante l'accredito di <BoldSpan>parte dello stipendio</BoldSpan> del
      lavoratore direttamente dall'azienda, che non può rifiutarsi. È uno degli
      strumenti di finanziamento dei lavoratori più diffusi.
    </AnswerBox>
  );
};

const AnswerBoxTwo: React.FC = () => {
  return (
    <AnswerBox>
      Il lavoratore non può cedere la parte di stipendio che ritiene più
      soddisfacente per pagare il proprio debito, né la banca/finanziaria può
      imporre all'interessato di cederle una determinata quota di stipendio. C'è
      infatti un preciso limite legale:{' '}
      <BoldSpan>
        la somma massima cedibile è pari a un quinto dello stipendio mensile.
      </BoldSpan>
      <SpaceTop />
      C'è anche un <BoldSpan>limite temporale di durata</BoldSpan> della
      cessione:
      <AnswerBoxList marginTop="28px">
        <li>
          per i lavoratori a tempo indeterminato, la durata massima è di
          <BoldSpan>10 anni</BoldSpan>;
        </li>
        <li>
          per i lavoratori a tempo determinato, invece, la modalità di rimborso
          del finanziamento
          <BoldSpan>non può superare la durata del contratto.</BoldSpan>
        </li>
      </AnswerBoxList>
      <SpaceTop />
      Sono limiti inderogabili: la pattuizione di una cessione oltre il quinto è{' '}
      <BoldSpan>nulla, perché contraria alla legge</BoldSpan>. In questo modo si
      vuole evitare che gli istituti finanziatori possano chiedere ai lavoratori
      di indebitarsi cedendo a loro una parte di stipendio superiore al quinto.
    </AnswerBox>
  );
};

const AnswerBoxThree: React.FC = () => {
  return (
    <AnswerBox>
      <BoldSpan>Sì</BoldSpan>, il limite alla cessione del quinto si applica a
      tutte le mensilità, anche alle mensilità aggiuntive come tredicesima,
      quattordicesima e anche al premio di produttività, nel caso in cui venisse
      corrisposto.
      <SpaceTop />
      Questo limite <BoldSpan>non si applica</BoldSpan>, invece, per espressa
      deroga di legge, <BoldSpan>al TFR</BoldSpan>, che quindi può essere
      interamente ceduto alla banca/finanziaria.
    </AnswerBox>
  );
};

const AnswerBoxFour: React.FC = () => {
  return (
    <AnswerBox>
      <BoldSpan>No</BoldSpan>, è un limite che{' '}
      <BoldSpan>non può essere derogato dalle parti.</BoldSpan> Questo significa
      che, ad esempio, qualora un dipendente percepisca uno stipendio di 2.500
      euro mensili, non può cedere più di 500 euro al mese.
      <SpaceTop />
      <SpaceTop />
      Gli istituti non possono imporre una cessione di una percentuale maggiore
      dello stipendio, poiché questo particolare strumento di finanziamento ha
      precisi limiti e obblighi di legge.
    </AnswerBox>
  );
};

export { AnswerBoxFour, AnswerBoxOne, AnswerBoxThree, AnswerBoxTwo };
