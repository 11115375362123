import {
  COLORS,
  Image404,
  LBTButton,
  LBTButtonDefaultTypography,
  LBTLabel,
  LBTSubtitle,
  LBTTitle,
} from '@laborability/components';
import Section from '../components/Section';
import useTrackPage from '../hooks/useTrackPage';
import { useBreakpoint } from '@laborability/commons';
import { SpaceTop } from '../components/answerBoxes/Space';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../Routes';

const NoPage404 = () => {
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  useTrackPage('Pagina non trovata', '/app/error-404');

  return (
    <Section
      backgroundColor={COLORS.getInstance().WHITE}
      style={{ display: 'flex', flex: 1 }}
    >
      <SpaceTop margin="64px" />
      <LBTTitle>Puf! Pagina svanita</LBTTitle>
      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
      <LBTSubtitle>
        Hai raggiunto una pagina che non è più online. Potremmo averla
        cancellata oppure averle cambiato posizione
      </LBTSubtitle>
      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
      <Image404 width={isDesktop ? 300 : 200} height={isDesktop ? 300 : 200} />
      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
      <LBTLabel>
        Gli errori 404 sono così: arrivano quando meno te lo aspetti e non si
        sanno spiegare troppo bene. Nel dubbio:
      </LBTLabel>
      <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      <LBTButton onClick={() => navigate(APP_ROUTES.HOME)} variant="contained">
        <LBTButtonDefaultTypography fontSize="14px">
          Torna alla home
        </LBTButtonDefaultTypography>
      </LBTButton>
      <SpaceTop margin={isDesktop ? '48px' : '32px'} />
    </Section>
  );
};

export default NoPage404;
