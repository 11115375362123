export interface Question {
  id?: number;
  name?: string;
  text?: string;
  hint?: string;
  question_type?: string;
  question_meta?: { [x: string]: unknown };
  attribute_meta_id?: number;
  required?: boolean;
}

export interface PageAction {
  id?: number;
  page_id?: number;
  name?: string;
  action_type?: string;
  action_meta?: { [x: string]: unknown };
  target_page_id?: number;
}

export interface QuestionPage {
  id?: number;
  flow_id?: number;
  name?: string;
  hint?: string;
  description?: string;
  media_link?: string;
  questions?: Question[];
  actions?: PageAction[];
}

export interface PostQuestion extends Question {
  flow_id: number;
  page_id: number;
}

export interface PostQuestionPage
  extends Omit<QuestionPage, 'questions' | 'actions'> {
  flow_id: number;
}

export class QuestionPageClass implements QuestionPage {
  id = 0;
  flow_id = 0;
  name = '';
  hint = '';
  description = '';
  media_link = '';
  questions = [];
  actions = [];
}

export class PageActionClass implements PageAction {
  id = 0;
  page_id = 0;
  name = '';
  action_type = '';
  action_meta = undefined;
  target_page_id = 0;
}

export class QuestionClass implements Question {
  id = 0;
  name = '';
  text = '';
  hint = '';
  question_type = '';
  question_meta = undefined;
  attribute_meta_id = 0;
  required = false;
}
