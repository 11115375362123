import {
  COLORS,
  FONTS,
  DefaultAccordionSummaryTypography,
  IconArrowRightComponent,
  IconCrossComponent,
  IconEnteComponent,
  IconsSize,
  IconsStyle,
  LBTAccordion,
  LBTCard,
  LBTDivider,
  LBTLabel,
  LBTPagination,
  LBTTitle,
  LBTSubtitle,
  CardVariant,
} from '@laborability/components';
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  SwipeableDrawer,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import LogoAssocaaf from '../../assets/images/assocaaf.svg';
import AssocaafComunicazioneEnea from '../../components/Assocaaf/AssocaafComunicazioneEnea';
import AssocaafConsulenzaFiscale from '../../components/Assocaaf/AssocaafConsulenzaFiscale';
import AssocaafConsulenzaRistrutturazioni from '../../components/Assocaaf/AssocaafConsulenzaRistrutturazioni';
import AssocaafIMU from '../../components/Assocaaf/AssocaafIMU';
import AssocaafISEE from '../../components/Assocaaf/AssocaafISEE';
import AssocaafModello730 from '../../components/Assocaaf/AssocaafModello730';
import { ChangeScrollColor } from '../../components/ChangeScrollColor';
import Section from '../../components/Section';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import { SpaceTop } from '../../components/answerBoxes/Space';
import { navMenuState, swipeableDrawerState } from '../../recoil/states/global';
import { MENU_HEIGHT } from '../Home';
import styled from 'styled-components';
import BonusList from '../../components/measuresPage/BonusList';
import BonusListVariantLeft from '../../components/measuresPage/BonusListVariantLeft';
import AlertsList from '../../components/measuresPage/AlertsList';
import ExternalSiteButton from '../../components/measuresPage/ExternalSiteButton';
import BonusListVariantRight from '../../components/measuresPage/BonusListVariantRight';
import {
  Misura,
  getMisuraByIdCallback,
  getMisureByCategoryIdCallback,
  useBreakpoint,
  getBenefitByIdCallback,
  measureState,
  benefitState,
  currentCompanyConfigState,
} from '@laborability/commons';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';
import useTrackPage from '../../hooks/useTrackPage';

const StyledImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img<{ maxwidth: string }>`
  width: 100%;
  max-width: ${props => props.maxwidth};
`;
export const Image = ({ src }: { src: string }) => {
  return (
    <StyledImageContainer>
      <StyledImage src={src} maxwidth={'100%'} />
    </StyledImageContainer>
  );
};

const StyledListIcon = styled(ListItemIcon)<
  ListItemIconProps & { marginTop?: string; justifyContent?: string }
>(({ marginTop, justifyContent }) => ({
  marginTop: marginTop,
  justifyContent: justifyContent,
}));

const HowItWorksParagraph = styled(Typography)<TypographyProps>(props => ({
  '& *': {
    margin: '0',
  },
}));

const SERVICES = [
  {
    title: 'Modello 730',
    description:
      "Assocaaf prepara e invia all'Agenzia delle Entrate la tua dichiarazione dei redditi",
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafModello730 />
      </Box>
    ),
  },
  {
    title: 'ISEE',
    description:
      "Assocaaf richiede per te l'Indicatore della Situazione Economica Equivalente",
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafISEE />
      </Box>
    ),
  },
  {
    title: 'IMU',
    description:
      "Assocaaf prepara gli F24 per pagare l'IMU e li invia all'Agenzia delle Entrate al posto tuo",
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafIMU />
      </Box>
    ),
  },
  {
    title: 'Comunicazione ENEA',
    description:
      'Assocaaf invia la comunicazione necessaria per usufruire delle detrazioni legate agli interventi di risparmio energetico',
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafComunicazioneEnea />
      </Box>
    ),
  },
  {
    title: 'Consulenza ristrutturazioni',
    description:
      "Assocaaf gestisce tutte le pratiche con l'Agenzia delle Entrate per ottenere sconto in fattura o cessione del credito ",
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafConsulenzaRistrutturazioni />
      </Box>
    ),
  },
  {
    title: 'Consulenza fiscale generica',
    description:
      'Assocaaf studia la tua situazione e ti consiglia la soluzione più conveniente',
    component: (
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <AssocaafConsulenzaFiscale />
      </Box>
    ),
  },
];

const ACCORDION_ITEMS = [
  {
    question: 'A chi è rivolto',
    key: 'target_audience',
  },
  {
    question: 'Requisiti per accedere',
    key: 'access_requirements',
  },
  {
    question: 'Valore del bonus',
    key: 'bonus_value',
  },
  {
    question: 'Quando fare domanda',
    key: 'application_period',
  },
  {
    question: 'Come fare domanda',
    key: 'application_process',
  },
];

const IndennitaPage = () => {
  const { id } = useParams();
  const { isDesktop } = useBreakpoint();
  const { assocaf } = useRecoilValue(currentCompanyConfigState);
  const navigate = useNavigate();
  const setNavColor = useSetRecoilState(navMenuState);
  const misura = useRecoilValue(measureState);
  const beneficio = useRecoilValue(benefitState);

  const fetchMisure = useRecoilCallback(getMisureByCategoryIdCallback, []);
  const fetchMisuraById = useRecoilCallback(getMisuraByIdCallback, []);
  const fetchBenefitTypeById = useRecoilCallback(getBenefitByIdCallback, []);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [misure, setMisure] = useState<Misura[]>([]);

  const [measureId, setMeasureId] = useState<string>();
  const [measureName, setMeasureName] = useState<string>();

  const [
    { open, title, component: componentDrawer, description },
    setSwipeableDrawerState,
  ] = useRecoilState(swipeableDrawerState);

  const toggleDrawer = (newOpen: boolean) => () => {
    setSwipeableDrawerState(prev => ({
      ...prev,
      open: newOpen,
      title: 'Modello 730',
      description:
        "Assocaaf prepara e invia all'Agenzia delle Entrate la tua dichiarazione dei redditi",
      component: (
        <IconEnteComponent
          size={IconsSize.LARGE}
          style={IconsStyle.FILLED}
          color={COLORS.getInstance().BW_GREYS_JET_BLACK}
        />
      ),
    }));
  };

  const renderItem = (item: Misura) => {
    return (
      <Grid item mobile={12} desktop={6} display="flex">
        <LBTCard
          onClick={() => navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)}
          title={item.name}
          description={item.description}
          tag={item.categories
            ?.find(category =>
              item.categories!.length === 2
                ? !!category.parent_id
                : category.parent_id === null,
            )
            ?.name?.toLowerCase()}
          news={item.news ?? false}
          expired={item.expired ?? false}
          expiringSoon={item.expiring_soon ?? false}
          cardVariant={CardVariant.WHITE}
          upcoming={item.upcoming ?? false}
        />
      </Grid>
    );
  };

  const getMisure = async () => {
    if (!misura?.categories_ids?.length) return;
    const res = await fetchMisure({
      id: misura.categories_ids?.[0],
      limit: 5,
      published: true,
    });

    setMisure(
      res?.data.items
        .filter((item: Misura) => item.id !== Number(id))
        .slice(0, 4),
    );
  };

  useEffect(() => {
    setNavColor(COLORS.getInstance().PRIMARY_SUPERLIGHT);
    fetchMisuraById({ id: Number(id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (misura.benefit_type_id)
      fetchBenefitTypeById({ id: misura.benefit_type_id });
    getMisure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [misura]);

  useEffect(() => {
    if (misura.id?.toString() === window.location.href.split('/').pop()) {
      setMeasureId(misura.id?.toString());
      setMeasureName(misura.name);
    }
  }, [misura]);

  useTrackPage(
    !!measureName ? `Misura ${measureName}` : '',
    !!measureId ? `/app/misura/${measureId}` : '',
  );

  const anyAlertSelected: boolean | undefined =
    misura?.for_all_incomes || misura?.question_online_or_phone;

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().PRIMARY_SUPERLIGHT}>
        <ChangeScrollColor
          color={COLORS.getInstance().PRIMARY_SUPERLIGHT}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>{misura?.name}</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTSubtitle>{misura?.description}</LBTSubtitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <Grid
          container
          gridAutoRows="1fr"
          spacing={isDesktop ? '24px' : '16px'}
          justifyContent="center"
        >
          {!isDesktop ? (
            <>
              {!!misura?.image_path || !!misura?.video_path ? (
                <Grid
                  item
                  display="flex"
                  width={isDesktop ? '417px' : '100%'}
                  height={isDesktop ? '645px' : '554px'}
                >
                  {!!misura.image_path ? (
                    <Box
                      component="img"
                      src={misura?.image_path}
                      width="100%"
                    />
                  ) : (
                    <Box width="100%">
                      <embed
                        src={misura.video_path}
                        type="video/mp4"
                        width="100%"
                        height="100%"
                        title="Keyboard Cat"
                      />
                    </Box>
                  )}
                </Grid>
              ) : (
                <></>
              )}
              <Grid
                item
                direction="column"
                display="flex"
                justifyContent="space-between"
                width={isDesktop ? '417px' : '100%'}
              >
                <Box>
                  <AlertsList misura={misura} isDesktop={isDesktop} />
                </Box>
                <Box>
                  <BonusList
                    misura={misura}
                    beneficio={beneficio}
                    isDesktop={isDesktop}
                  />
                  <SpaceTop margin="16px" />
                  <ExternalSiteButton misura={misura} position="center" />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              {misura?.image_path === null && misura.video_path === null ? (
                anyAlertSelected ? (
                  <>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      width={isDesktop ? '417px' : '100%'}
                    >
                      <AlertsList misura={misura} isDesktop={isDesktop} />
                      <SpaceTop margin="32px" />
                      <ExternalSiteButton
                        misura={misura}
                        position="flex-start"
                      />
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      direction="column"
                      width={isDesktop ? '417px' : '100%'}
                    >
                      <BonusList
                        misura={misura}
                        beneficio={beneficio}
                        isDesktop={isDesktop}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item width={isDesktop ? '417px' : '100%'}>
                      <BonusListVariantLeft
                        misura={misura}
                        beneficio={beneficio}
                        isDesktop={isDesktop}
                      />
                    </Grid>
                    <Grid
                      item
                      display="flow"
                      direction="column-reverse"
                      justifyContent="space-between"
                      width={isDesktop ? '417px' : '100%'}
                    >
                      <BonusListVariantRight
                        misura={misura}
                        isDesktop={isDesktop}
                      />
                      <SpaceTop margin="32px" />
                      <ExternalSiteButton
                        misura={misura}
                        position="flex-start"
                      />
                    </Grid>
                  </>
                )
              ) : (
                <>
                  <Grid
                    item
                    display="flex"
                    width={isDesktop ? '417px' : '100%'}
                    height={isDesktop ? '645px' : '554px'}
                  >
                    {!!misura.image_path ? (
                      <Box
                        component="img"
                        src={misura?.image_path}
                        width="100%"
                      />
                    ) : (
                      <Box
                        component="iframe"
                        border="none"
                        src={misura?.video_path}
                        width="100%"
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    direction="column"
                    display="flex"
                    justifyContent="space-between"
                    width={isDesktop ? '417px' : '100%'}
                  >
                    <Box>
                      <BonusList
                        misura={misura}
                        beneficio={beneficio}
                        isDesktop={isDesktop}
                      />
                      <SpaceTop margin={isDesktop ? '32px' : '16px'} />
                      <ExternalSiteButton misura={misura} />
                    </Box>
                    <Box>
                      <AlertsList misura={misura} isDesktop={isDesktop} />
                    </Box>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
        <SpaceTop margin="32px" />
      </Section>
      {misura.how_it_works && (
        <Section backgroundColor={COLORS.getInstance().WHITE}>
          <ChangeScrollColor
            color={COLORS.getInstance().WHITE}
            changeBreakpoint={MENU_HEIGHT}
          />
          <SpaceTop margin={isDesktop ? '48px' : '32px'} />
          <LBTTitle>Come funziona?</LBTTitle>
          <SpaceTop margin={isDesktop ? '48px' : '32px'} />

          <HowItWorksParagraph
            maxWidth={'680px'}
            textAlign="left"
            fontSize="16px"
            lineHeight="normal"
            width={'100%'}
            fontFamily={FONTS.SOURCE_SANS_PRO}
            dangerouslySetInnerHTML={{ __html: misura.how_it_works as string }}
          />

          <SpaceTop margin={isDesktop ? '48px' : '32px'} />
          {misura &&
            ACCORDION_ITEMS.map((item, index) => {
              const span = document.createElement('span');
              span.innerHTML = misura[item.key as keyof Misura] as string;
              if (
                span.textContent?.trim() !== '' ||
                span.innerText.trim() !== ''
              ) {
                return (
                  misura[item.key as keyof Misura] !== null && (
                    <LBTAccordion
                      sx={{ width: '100%' }}
                      uniqueKey={`${index}`}
                      key={index}
                      details={
                        <Typography
                          maxWidth={'680px'}
                          fontSize="16px"
                          lineHeight="normal"
                          width={'100%'}
                          fontFamily={FONTS.SOURCE_SANS_PRO}
                          color={COLORS.getInstance().BW_GREYS_JET_BLACK}
                          dangerouslySetInnerHTML={{
                            __html: misura[item.key as keyof Misura] as string,
                          }}
                        />
                      }
                      summary={
                        <DefaultAccordionSummaryTypography
                          color={COLORS.getInstance().BW_GREYS_JET_BLACK}
                          children={item.question}
                        />
                      }
                    />
                  )
                );
              } else {
                return <></>;
              }
            })}
          <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        </Section>
      )}

      {assocaf && (
        <Section backgroundColor={COLORS.getInstance().PRIMARY_SUPERLIGHT}>
          <ChangeScrollColor
            color={COLORS.getInstance().PRIMARY_SUPERLIGHT}
            changeBreakpoint={MENU_HEIGHT}
          />
          <SpaceTop margin={isDesktop ? '48px' : '32px'} />
          <LBTTitle>Ti serve aiuto per fare domanda?</LBTTitle>
          <SpaceTop margin={isDesktop ? '48px' : '32px'} />
          <LBTSubtitle>
            Attraverso la partnership con Assocaaf, avrai a disposizione un
            supporto esclusivo per portare a termine le tue pratiche
          </LBTSubtitle>
          <SpaceTop margin={isDesktop ? '48px' : '32px'} />
          <Box maxWidth="164px" maxHeight="50px">
            <Image src={LogoAssocaaf} />
          </Box>
          <SpaceTop margin={isDesktop ? '32px' : '16px'} />
          <LBTLabel maxWidth={'680px'} fontSize="14px" lineHeight="20px">
            Assocaaf Spa è il centro di assistenza fiscale costituito dalle
            associazioni territoriali e di categoria di Confindustria e da oltre
            mille imprese nazionali e multinazionali
          </LBTLabel>
          <SpaceTop margin={isDesktop ? '64px' : '48px'} />

          <List disablePadding sx={{ maxWidth: '680px' }}>
            <LBTLabel
              fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
              textAlign="left"
              fontSize="17px"
              lineHeight="24px"
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            >
              Servizi disponibili
            </LBTLabel>
            <SpaceTop margin={isDesktop ? '32px' : '16px'} />
            <LBTDivider orientation="horizontal" width={'100%'} />
            {SERVICES.map((item, index) => (
              <>
                <ListItem
                  key={index}
                  disablePadding
                  onClick={() => {
                    setSwipeableDrawerState(prev => ({
                      ...prev,
                      open: true,
                      title: item.title,
                      description: item.description,
                      component: item.component,
                    }));
                  }}
                >
                  <ListItemText
                    sx={{ padding: '8px 0px', margin: '0px' }}
                    primary={
                      <LBTLabel
                        textAlign="left"
                        fontSize="16px"
                        lineHeight="24px"
                        color={COLORS.getInstance().BW_GREYS_JET_BLACK}
                      >
                        {item.title}
                      </LBTLabel>
                    }
                    secondary={
                      <LBTLabel
                        color={COLORS.getInstance().BW_GREYS_DARK}
                        textAlign="left"
                        fontSize="14px"
                        lineHeight="16.8px"
                      >
                        {item.description}
                      </LBTLabel>
                    }
                  />
                  <StyledListIcon justifyContent="center">
                    <IconArrowRightComponent
                      size={IconsSize.MEDIUM}
                      style={IconsStyle.OUTLINE}
                      color={COLORS.getInstance().BLACK}
                    />
                  </StyledListIcon>
                </ListItem>
                <LBTDivider orientation="horizontal" width={'100%'} />
              </>
            ))}
          </List>
          <SpaceTop margin={isDesktop ? '48px' : '32px'} />
          <Typography
            fontFamily={FONTS.SOURCE_SANS_PRO}
            fontSize={'14px'}
            fontStyle={'normal'}
            fontWeight={'400'}
            lineHeight={'20px'}
            maxWidth={'680px'}
          >
            Per farti aiutare, scrivi a{' '}
            <Link
              href="mailto:laborability@assocaaf.it"
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            >
              <BoldSpan>laborability@assocaaf.it</BoldSpan>
            </Link>
          </Typography>
          <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        </Section>
      )}

      <Section backgroundColor={COLORS.getInstance().PRIMARY_IPERLIGHT}>
        <ChangeScrollColor
          color={COLORS.getInstance().PRIMARY_IPERLIGHT}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        <LBTTitle>Ti potrebbero interessare</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTSubtitle>
          Esplora bonus affini a quello che hai appena consultato
        </LBTSubtitle>
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        <Grid
          container
          spacing={isDesktop ? '24px' : '16px'}
          sx={{
            maxWidth: isDesktop ? '1032px' : undefined,
          }}
        >
          {isDesktop
            ? misure.map(item => renderItem(item))
            : misure.length && renderItem(misure[currentPage - 1])}
        </Grid>
        {!isDesktop && (
          <LBTPagination
            numberOfItems={misure.length}
            onPageChange={tmpPage => setCurrentPage(tmpPage)}
            currentPage={currentPage}
          />
        )}
        <SpaceTop margin={isDesktop ? '64px' : '48px'} />
      </Section>
      <SwipeableDrawer
        container={undefined}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '24px',
          }}
        >
          <Typography
            fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
            fontSize={'20px'}
            lineHeight={'26px'}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          >
            {title}
          </Typography>
          <Box
            sx={{ position: 'absolute', right: '0px', marginRight: '16px' }}
            onClick={() => {
              setSwipeableDrawerState(prev => ({
                ...prev,
                open: false,
              }));
            }}
          >
            <IconCrossComponent
              size={IconsSize.MEDIUM}
              style={IconsStyle.FILLED}
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            ></IconCrossComponent>
          </Box>
        </Box>
        <SpaceTop margin="16px" />
        <Typography
          fontFamily={FONTS.SOURCE_SANS_PRO}
          fontWeight={400}
          fontSize={'14px'}
          lineHeight={'20px'}
          textAlign={'center'}
          padding={'0px 16px'}
          color={COLORS.getInstance().BW_GREYS_JET_BLACK}
        >
          {description}
        </Typography>
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          {componentDrawer}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default IndennitaPage;
