import { useEffect, useMemo } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
// import usePageTracking from '../hooks/useTrackLocation';
import { navMenuState } from '../recoil/states/global';
import ScrollToTop from './ScrollToTop';
import { SpaceTop } from './answerBoxes/Space';
import { COLORS } from '@laborability/components';
import NavMenu from './Layouts/NavMenu';
import Footer from './Layouts/Footer';
import { useBreakpoint } from '@laborability/commons';

type HandlePageColor = {
  pageColor: string;
};

type HandlePageTitle = {
  page_title: string;
};

const Layout = () => {
  const matches = useMatches();
  const bgC = useRecoilValue(navMenuState);
  const { isDesktop } = useBreakpoint();
  // usePageTracking();
  const bgColor = useMemo(() => {
    const pHandleColor = matches.find(
      match =>
        'handle' in match &&
        match.handle &&
        (match.handle as HandlePageColor).pageColor,
    );
    return pHandleColor
      ? (pHandleColor.handle as HandlePageColor).pageColor
      : COLORS.getInstance().PRIMARY_MAIN;
  }, [matches]);

  const pageTitle = useMemo(() => {
    const pHandle = matches.find(
      match =>
        'handle' in match &&
        match.handle &&
        (match.handle as HandlePageTitle).page_title,
    );
    return pHandle
      ? (pHandle.handle as HandlePageTitle).page_title
      : 'Laborability - FE APP';
  }, [matches]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      {/* <GA4></GA4> */}
      <ScrollToTop />
      <NavMenu backgroundColor={bgC} />
      <SpaceTop margin={isDesktop ? '100px' : '64px'} />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
