import Radio, { RadioProps } from '@mui/material/Radio';
import styled from 'styled-components';
import { COLORS } from '../../utils';
import DisabledCheckedRadioButton from './DisabledCheckedRadioButton';
import DisabledUncheckedRadioButton from './DisabledUncheckedRadioButton';

export type LBTRadioProps = RadioProps & {
  variant?: string;
  datatestid?: string;
};

const StyledRadioButton = styled(Radio)<
  RadioProps & {
    variant?: string;
    datatestid?: string;
  }
>(({ theme, variant, datatestid, ...props }) => ({
  datatestid,
  '&.MuiRadio-root': {
    color: !props.disabled ? variant : '#E8E8E8',
  },
  '&.Mui-checked': {
    '&.MuiRadio-root': {
      color: !props.disabled ? variant : '#E8E8E8',
    },
  },
}));

const LBTRadioButton: React.FC<LBTRadioProps> = ({
  variant = COLORS.getInstance().BLACK,
  datatestid = '',
  ...props
}: LBTRadioProps) => {
  return !props.disabled ? (
    <StyledRadioButton
      variant={variant}
      datatestid={`lbt-radio-${datatestid}`}
      {...props}
    />
  ) : (
    <StyledRadioButton
      icon={<DisabledUncheckedRadioButton />}
      checkedIcon={<DisabledCheckedRadioButton />}
      datatestid={`lbt-radio-${datatestid}`}
      {...props}
    />
  );
};

export default LBTRadioButton;
