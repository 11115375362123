const ImageInstragramComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_2812_13622)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1429 1.52344C10.2674 1.52344 8.44 1.69144 6.68457 1.88515C5.2842 2.04466 3.97949 2.67496 2.98412 3.67282C1.98875 4.67068 1.36172 5.97696 1.20571 7.37772C1.02228 9.12287 0.862854 10.94 0.862854 12.8017C0.862854 14.6634 1.02057 16.4806 1.20571 18.2257C1.36164 19.6262 1.98843 20.9323 2.98345 21.9301C3.97848 22.928 5.28279 23.5584 6.68285 23.7183C8.43657 23.912 10.2657 24.08 12.1411 24.08C14.0149 24.08 15.844 23.912 17.5994 23.7183C18.9994 23.5581 20.3035 22.9276 21.2985 21.9298C22.2935 20.932 22.9203 19.6261 23.0766 18.2257C23.2617 16.4806 23.4194 14.6634 23.4194 12.8017C23.4194 10.94 23.2617 9.12287 23.0766 7.37772C22.9204 5.97759 22.2938 4.67188 21.2992 3.67414C20.3045 2.6764 19.0008 2.04568 17.6011 1.88515C15.8457 1.69315 14.0183 1.52515 12.1429 1.52515V1.52344ZM18.352 5.06858C18.9434 5.06858 19.4234 5.54858 19.4234 6.14001V6.89087C19.4234 7.17503 19.3105 7.44755 19.1096 7.64848C18.9087 7.84941 18.6362 7.96229 18.352 7.96229C18.0678 7.96229 17.7953 7.84941 17.5944 7.64848C17.3934 7.44755 17.2806 7.17503 17.2806 6.89087V6.14001C17.2806 5.54858 17.7606 5.06858 18.352 5.06858ZM16.6171 12.8017C16.6171 15.6646 15.0057 17.276 12.1429 17.276C9.28 17.276 7.66857 15.6646 7.66857 12.8017C7.66857 9.93886 9.28 8.32744 12.1429 8.32744C15.0057 8.32744 16.6171 9.93886 16.6171 12.8017Z"
          fill="#80ABF9"
        />
      </g>
      <defs>
        <clipPath id="clip0_2812_13622">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.142883 0.801758)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImageInstragramComponent;
