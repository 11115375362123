import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import * as React from 'react';
import styled from 'styled-components';
import LBTLabel from '../Label';

export type LBTProgressSpinnerProps = CircularProgressProps & {
  datatestid?: string;
};

const StyledProgressSpinner = styled(CircularProgress)<
  CircularProgressProps & {
    datatestid: string;
  }
>(({ datatestid }) => ({
  datatestid,
  circle: {
    strokeLinecap: 'round',
  },
}));

const StyledProgressSpinnerContainer = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  display: 'inline-flex',
}));

const StyledProgressSpinnerLabelBox = styled(Box)<BoxProps>(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LBTProgressSpinner: React.FC<LBTProgressSpinnerProps> = ({
  datatestid = '',
  ...props
}: LBTProgressSpinnerProps) => {
  return (
    <StyledProgressSpinnerContainer>
      <StyledProgressSpinner
        {...props}
        datatestid={datatestid}
        thickness={6}
        size={48}
      />
      {props.variant === 'determinate' ? (
        <StyledProgressSpinnerLabelBox>
          <LBTLabel fontSize="12px">{`${props.value!.toString()}%`}</LBTLabel>
        </StyledProgressSpinnerLabelBox>
      ) : (
        <></>
      )}
    </StyledProgressSpinnerContainer>
  );
};

export default LBTProgressSpinner;
