import { Benefit, Misura } from '@laborability/commons';
import {
  COLORS,
  IconEuroComponent,
  IconInboxComponent,
  IconLocationComponent,
  IconsSize,
  IconsStyle,
  LBTLabel,
} from '@laborability/components';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  styled,
} from '@mui/material';

const StyledListIcon = styled(ListItemIcon)<
  ListItemIconProps & { marginTop?: string; justifyContent?: string }
>(({ marginTop, justifyContent }) => ({
  marginTop: marginTop,
  justifyContent: justifyContent,
}));

const BonusListVariantLeft: React.FC<{
  misura?: Misura;
  beneficio?: Benefit;
  isDesktop: boolean;
}> = ({ misura, beneficio, isDesktop }) => {
  return (
    <List
      disablePadding
      sx={{
        order: isDesktop ? 1 : 3,
        marginTop: isDesktop ? 0 : '16px',
      }}
    >
      <ListItem disablePadding alignItems="flex-start">
        <StyledListIcon>
          <IconInboxComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        </StyledListIcon>
        <ListItemText
          sx={{ padding: '8px 0px', margin: '0px' }}
          primary={
            <LBTLabel
              textAlign="left"
              fontSize="16px"
              lineHeight="24px"
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            >
              {beneficio?.name ?? ''}
            </LBTLabel>
          }
          secondary={
            <LBTLabel
              color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
              textAlign="left"
              fontSize="14px"
              lineHeight="16.8px"
            >
              {beneficio?.description ?? ''}
            </LBTLabel>
          }
        />
      </ListItem>

      {misura?.final_range && misura?.initial_range && (
        <ListItem disablePadding alignItems="flex-start">
          <StyledListIcon>
            <IconEuroComponent
              size={IconsSize.LARGE}
              style={IconsStyle.FILLED}
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            />
          </StyledListIcon>
          <ListItemText
            sx={{ padding: '8px 0px', margin: '0px' }}
            primary={
              <LBTLabel
                textAlign="left"
                fontSize="16px"
                lineHeight="24px"
                color={COLORS.getInstance().BW_GREYS_JET_BLACK}
              >
                {`${misura?.initial_range}-${misura?.final_range}€`}
              </LBTLabel>
            }
            secondary={
              <LBTLabel
                color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
                textAlign="left"
                fontSize="14px"
                lineHeight="16.8px"
              >
                Riceverai una somma compresa in questo intervallo
              </LBTLabel>
            }
          />
        </ListItem>
      )}

      <ListItem disablePadding alignItems="flex-start">
        <StyledListIcon>
          <IconLocationComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        </StyledListIcon>
        <ListItemText
          sx={{ padding: '8px 0px', margin: '0px' }}
          primary={
            <LBTLabel
              textAlign="left"
              fontSize="16px"
              lineHeight="24px"
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            >
              {misura?.national ? 'Nazionale' : 'Regionale'}
            </LBTLabel>
          }
          secondary={
            <LBTLabel
              color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
              textAlign="left"
              fontSize="14px"
              lineHeight="16.8px"
            >
              {misura?.national
                ? 'Richiedi questo bonus in tutto il territorio italiano'
                : 'Questo bonus è disponibile in'}
            </LBTLabel>
          }
        />
      </ListItem>
    </List>
  );
};

export default BonusListVariantLeft;
