import chroma from 'chroma-js';
import { AppTheme, COLORS } from '@laborability/components/src/utils';
import { CompanyConfig } from '../recoil/types';
import { useRecoilCallback } from 'recoil';
import { getAllCompanyConfigurationByRealmNameCallback } from '../recoil';

interface Returns {
  setCompanyConfig: (name: string) => Promise<any>;
}

export default function useCompanyConfig(): Returns {
  const fetchCompanyConfig = useRecoilCallback(
    getAllCompanyConfigurationByRealmNameCallback,
    [],
  );

  const setColors = (primary?: string, secondary?: string) => {
    const primaryPalette = primary
      ? {
          main: primary,
          dark: chroma(primary).darken().hex(),
          light: chroma(primary).brighten().hex(),
          superlight: chroma(primary).mix('#FFFFFF', 0.5).hex(), // valore giusto
          iperlight: chroma(primary).mix('#FFFFFF', 0.77).hex(), //// valore non completamente esatto, e4edff
          contrastText:
            chroma(primary).luminance() > 0.5 ? '#282828' : '#FFFFFF',
        }
      : undefined;

    const secondaryPalette = secondary
      ? {
          main: secondary,
          dark: chroma(secondary).darken().hex(),
          light: chroma(secondary).brighten().hex(),
          contrastText:
            chroma(secondary).luminance() > 0.5 ? '#282828' : '#FFFFFF',
        }
      : undefined;

    const colors = COLORS.getInstance();
    if (primaryPalette) {
      AppTheme.palette.primary = primaryPalette as any;
      colors.PRIMARY_MAIN = primaryPalette.main;
      colors.PRIMARY_DARK = primaryPalette.dark;
      colors.PRIMARY_LIGHT = primaryPalette.light;
      colors.PRIMARY_SUPERLIGHT = primaryPalette.superlight;
      colors.PRIMARY_IPERLIGHT = primaryPalette.iperlight;
      colors.PRIMARY_CONTRAST = primaryPalette.contrastText;
    }
    if (secondaryPalette) {
      AppTheme.palette.secondary = secondaryPalette as any;
      colors.SECONDARY_MAIN = secondaryPalette.main;
      colors.SECONDARY_DARK = secondaryPalette.dark;
      colors.SECONDARY_LIGHT = secondaryPalette.light;
      colors.SECONDARY_CONTRAST = secondaryPalette.contrastText;
    }

    return { primary: primaryPalette, secondary: secondaryPalette };
  };

  const setCompanyConfig = async (name: string) => {
    console.log(`fetch ${name} config`);
    const res = await fetchCompanyConfig({ name });
    if (!res || !res.data)
      return {
        id: undefined,
        logo: undefined,
        assocaf: undefined,
        fifth_assignment: undefined,
        tfr: undefined,
      }; // TODO controllo risposta
    const primary_color = res.data.find(
      (d: CompanyConfig) => d.key === 'primary_color',
    )?.value;
    const secondary_color = res.data.find(
      (d: CompanyConfig) => d.key === 'secondary_color',
    )?.value;
    const id = res.data.find(
      (d: CompanyConfig) => d.key === 'primary_color',
    )?.company_id;
    const logo = res.data.find((d: CompanyConfig) => d.key === 'logo')?.value;
    const assocaf =
      res.data.find((d: CompanyConfig) => d.key === 'assocaf')?.value ===
      'true';
    const fifth_assignment =
      res.data.find((d: CompanyConfig) => d.key === 'fifth_assignment')
        ?.value === 'true';
    const tfr =
      res.data.find((d: CompanyConfig) => d.key === 'tfr')?.value === 'true';

    setColors(primary_color, secondary_color);
    return {
      id,
      logo,
      assocaf,
      fifth_assignment,
      tfr,
    };
  };

  return { setCompanyConfig };
}
