import * as React from 'react';
import styled from 'styled-components';

export type StyledDivProps = {
  margin?: string;
};

const StyledDiv = styled.div<{
  margin?: string;
}>(({ margin }) => ({
  margin,
}));

export const SpaceTop: React.FC<StyledDivProps> = ({
  margin = '16px',
}: StyledDivProps) => {
  const ref = React.useRef<string>(`${margin} 0 0 0`);
  return <StyledDiv margin={ref.current}></StyledDiv>;
};

export const SpaceBottom: React.FC<StyledDivProps> = ({
  margin = '16px',
}: StyledDivProps) => {
  const ref = React.useRef<string>(`0 0 ${margin} 0`);
  return <StyledDiv margin={ref.current}></StyledDiv>;
};

export const SpaceRight: React.FC<StyledDivProps> = ({
  margin = '16px',
}: StyledDivProps) => {
  const ref = React.useRef<string>(`0 ${margin} 0 0`);
  return <StyledDiv margin={ref.current}></StyledDiv>;
};

export const SpaceLeft: React.FC<StyledDivProps> = ({
  margin = '16px',
}: StyledDivProps) => {
  const ref = React.useRef<string>(`0 0 0 ${margin}`);
  return <StyledDiv margin={ref.current}></StyledDiv>;
};
