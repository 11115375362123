import {
  FONTS,
  LBTDivider,
  LBTDividerSize,
  LBTLabel,
} from '@laborability/components';
import Box, { BoxProps } from '@mui/material/Box';
import List, { ListProps } from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import React from 'react';
import styled from 'styled-components';
import { SpaceTop } from '../answerBoxes/Space';

export type LBTListProps = ListProps & {
  datatestid?: string;
};

export type LBTListItemProps = ListItemProps & {
  datatestid?: string;
};

const StyledList = styled(List)<ListProps & {}>(({ ...props }) => ({}));

const StyledListItem = styled(ListItem)<ListItemProps & {}>(({ ...props }) => ({
  padding: '16px 4px',
  minHeight: '57px',
}));

const CustomBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const AssocaafConsulenzaFiscale: React.FC<LBTListProps> = ({
  datatestid = '',
  ...props
}: LBTListProps) => {
  return (
    <>
      <LBTLabel
        fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
        fontSize="14px"
        lineHeight="16px"
        letterSpacing="0.9px"
        textAlign="left"
        sx={{ fontVariant: 'small-caps' }}
      >
        costi
      </LBTLabel>
      <SpaceTop margin="16px" />
      <StyledList disablePadding {...props}>
        <LBTDivider
          size={LBTDividerSize.MEDIUM}
          orientation="horizontal"
          width="100%"
        />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" fontSize="16px" lineHeight="20px">
              Su richiesta
            </LBTLabel>
            <LBTLabel
              textAlign="right"
              fontSize="16px"
              fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
              lineHeight="18px"
            >
              a partire da 50€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
      </StyledList>
    </>
  );
};

export default AssocaafConsulenzaFiscale;
