import {
  COLORS,
  FONTS,
  DefaultAccordionSummaryTypography,
  LBTAccordion,
  LBTTitle,
  LBTSubtitle,
} from '@laborability/components';
import { Typography } from '@mui/material';
import Section from '../../components/Section';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ChangeScrollColor } from '../../components/ChangeScrollColor';
import { SolaryFits } from '../../components/SolaryFits';
import { StaticArticles } from '../../components/StaticArticles';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxOne,
  AnswerBoxThree,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationSecondBoxes';
import { SpaceTop } from '../../components/answerBoxes/Space';
import { navMenuState } from '../../recoil/states/global';
import { MENU_HEIGHT } from '../Home';
import { ARTICLES } from './Fifth';
import { useBreakpoint } from '@laborability/commons';
import useTrackPage from '../../hooks/useTrackPage';

const ACCORDION_ITEMS = [
  {
    question: 'Come richiedere la cessione del quinto',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Quale documentazione bisogna presentare?',
    answer: <AnswerBoxTwo />,
  },
  {
    question: 'Chi non può cedere il quinto dello stipendio?',
    answer: <AnswerBoxThree />,
  },
];

const Fifth02 = () => {
  const { isDesktop } = useBreakpoint();
  const setNavColor = useSetRecoilState(navMenuState);

  useEffect(() => {
    setNavColor(COLORS.getInstance().TANGERINE_SUPERLIGHT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackPage(
    'Cessione del quinto - Articolo 2',
    '/app/cessione-quinto/articol-2',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().TANGERINE_SUPERLIGHT}>
        <ChangeScrollColor
          color={COLORS.getInstance().TANGERINE_SUPERLIGHT}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>Cessione del quinto, quanto posso chiedere?</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTSubtitle>
          Pagare un debito cedendo un quinto dello stipendio è una pratica
          diffusa, ecco come si chiede e quanto si può ottenere
        </LBTSubtitle>
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
        {ARTICLES[1].image}

        <Typography
          fontFamily={FONTS.SOURCE_SANS_PRO}
          fontSize={'16px'}
          fontStyle={'normal'}
          fontWeight={'400'}
          lineHeight={'22px'}
          paddingBottom={'16px'}
          paddingTop={'48px'}
          maxWidth={'680px'}
        >
          Lo <BoldSpan>stipendio, al netto delle ritenute</BoldSpan> operate su
          di esso, non può essere ridotto più del quinto per periodi superiori a
          10 anni, oltre a ciò bisogna anche considerare che lo stipendio non
          sia già ridotto da precedenti pignoramenti o delegazioni di pagamento.
          <SpaceTop />
          <BoldSpan>Esempio</BoldSpan> Ipotizzando che un lavoratore percepisca
          uno stipendio netto annuo 20.000 euro in 14 mensilità, senza che vi
          siano ulteriori trattenute a titolo di pignoramento o delegazioni di
          pagamento, il lavoratore a tempo indeterminato potrebbe ottenere fino
          a 333,33 euro mensili per 10 anni. 20.000,00/12=1.666,661.666,66 x
          20%= 333,33 euro netti (quota massima cedibile)
        </Typography>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            uniqueKey={`${index}`}
            details={item.answer}
            summary={
              <DefaultAccordionSummaryTypography children={item.question} />
            }
          ></LBTAccordion>
        ))}
        <SpaceTop margin={isDesktop ? '48px' : '32px'} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <ChangeScrollColor
          color={COLORS.getInstance().WHITE}
          changeBreakpoint={MENU_HEIGHT}
        />
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <LBTTitle>Vai ai prossimi articoli</LBTTitle>
        <SpaceTop margin={isDesktop ? '32px' : '16px'} />
        <StaticArticles toExclude={[1]}></StaticArticles>
      </Section>
    </>
  );
};

export default Fifth02;
