import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { COLORS, FONTS } from '../../utils';
import IconArrowDownComponent from '../Icons/IconArrowDownComponent';
import { IconsSize, IconsStyle } from '../../enums';

const StyledAccordion = styled(Accordion)<
  AccordionProps & {
    datatestid: string;
    bgColor?: string;
    'data-track': string;
  }
>(({ datatestid, bgColor, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
  '&.MuiAccordion-root': {
    margin: '0',
    'background-color': bgColor ? bgColor : 'transparent',
    'box-shadow': 'none',
    '&::before': { content: 'none' },
    'max-width': '680px',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)<
  AccordionSummaryProps & { datatestid: string }
>(({ datatestid }) => ({
  datatestid,
  '&.MuiAccordionSummary-root': {
    padding: '11px 14px 11px 0',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
  '&.MuiAccordionSummary-root.Mui-expanded': {
    'min-height': '0px',
    margin: '0px',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)<
  AccordionDetailsProps & { datatestid: string }
>(({ datatestid }) => ({
  datatestid,
  '& *': {
    margin: '0',
  },
  '&.MuiAccordionDetails-root': {
    padding: '8px 16px 16px 16px',
  },
}));

export type LBTAccordionProps = Omit<AccordionProps, 'children'> & {
  datatestid?: string;
  uniqueKey: string;
  summary: React.ReactNode;
  details: React.ReactNode;
  expandIcon?: React.ReactNode;
};

export const DefaultAccordionSummaryTypography: React.FC<
  TypographyProps
> = props => (
  <Typography
    color={COLORS.getInstance().BW_GREYS_JET_BLACK}
    fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
    fontWeight={400}
    fontSize="20px"
    lineHeight="26px"
    {...props}
  />
);

export const DefaultAccordionDetailsTypography: React.FC<
  TypographyProps
> = props => (
  <Typography
    color={COLORS.getInstance().BW_GREYS_JET_BLACK}
    fontFamily={FONTS.SOURCE_SANS_PRO}
    fontWeight={400}
    fontSize="16px"
    lineHeight="20px"
    {...props}
  />
);

const LBTAccordion: React.FC<LBTAccordionProps> = ({
  datatestid = '',
  uniqueKey,
  summary,
  details,
  expandIcon = (
    <IconArrowDownComponent
      size={IconsSize.SMALL}
      color={COLORS.getInstance().BLACK}
      style={IconsStyle.OUTLINE}
    />
  ),
  ...props
}: LBTAccordionProps) => {
  return (
    <StyledAccordion
      disableGutters
      data-track={datatestid}
      datatestid={`lbt-accordion-${datatestid}`}
      {...props}
    >
      <StyledAccordionSummary
        datatestid={`lbt-accordion-summary-${datatestid}`}
        expandIcon={expandIcon}
        aria-controls={`${uniqueKey}-content`}
        id={uniqueKey}
      >
        {summary}
      </StyledAccordionSummary>
      <StyledAccordionDetails
        datatestid={`lbt-accordion-details-${datatestid}`}
      >
        {details}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default LBTAccordion;
