import { Stack, StackProps } from '@mui/material';
import styled from 'styled-components';

type SectionProps = StackProps & {
  backgroundColor?: string;
  datatestid?: string;
  children?: React.ReactNode;
};

const StyledStack = styled(Stack)<SectionProps>(
  ({ theme, backgroundColor, datatestid }) => ({
    backgroundColor,
    datatestid,
  }),
);

const Section = ({
  children,
  datatestid,
  backgroundColor,
  ...props
}: SectionProps) => {
  return (
    <StyledStack
      {...props}
      datatestid={`lbt-section-${datatestid}`}
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      backgroundColor={backgroundColor}
      padding={'0px 16px'}
    >
      {children}
    </StyledStack>
  );
};

export default Section;
