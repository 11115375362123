import AnswerBox from './AnswerBox';
import BoldSpan from './BoldSpan';

const AnswerBoxOne: React.FC = () => {
  return (
    <AnswerBox>
      Nel caso in cui il debitore abbia{' '}
      <BoldSpan>estinto anticipatamente il finanziamento</BoldSpan>, questi
      dovrà <BoldSpan>richiedere alla finanziaria</BoldSpan> il c.d. «
      <BoldSpan>conto estintivo</BoldSpan>», ossia il documento in cui la
      finanziaria dovrà indicare le rate versate alle scadenze concordate, la
      somma versata per l'estinzione, gli interessi, nonché le spese e le
      commissioni non maturate a seguito dell'estinzione anticipata.
    </AnswerBox>
  );
};

const AnswerBoxTwo: React.FC = () => {
  return (
    <AnswerBox>
      L'organo competente per offrire tutela stragiudiziale nel caso in cui il
      rimborso venga negato è l'
      <BoldSpan>Arbitro Bancario Finanziario</BoldSpan>, a cui si può rivolgere
      domanda di accertamento del diritto al rimborso delle spese.
    </AnswerBox>
  );
};

export { AnswerBoxOne, AnswerBoxTwo };
