import AnswerBox from './AnswerBox';
import BoldSpan from './BoldSpan';
import { SpaceTop } from './Space';

const AnswerBoxOne: React.FC = () => {
  return (
    <AnswerBox>
      Possono essere <BoldSpan>oggetto di cessione</BoldSpan> le{' '}
      <BoldSpan>pensioni</BoldSpan> e gli
      <BoldSpan>assegni di invalidità</BoldSpan>, le{' '}
      <BoldSpan>pensioni di vecchiaia</BoldSpan>, le{' '}
      <BoldSpan>pensioni di anzianità</BoldSpan>e le{' '}
      <BoldSpan>pensioni di inabilità</BoldSpan>, anche se liquidate in via
      provvisoria.
      <SpaceTop />
      Sono invece <BoldSpan>incedibili</BoldSpan>: gli assegni e le pensioni
      sociali, i trattamenti di invalidità civile, gli assegni mensili per
      l'assistenza ai pensionati con inabilità, gli assegni di sostegno al
      reddito, gli assegni al nucleo familiare, l'APE sociale.
      <SpaceTop />
      <BoldSpan>
        Non possono essere oggetto di cessione i trattamenti pensionistici
        integrati al minimo
      </BoldSpan>
      , in quanto, dovendo <BoldSpan>sempre</BoldSpan> essere{' '}
      <BoldSpan>
        garantita al pensionato la percezione del trattamento minimo
      </BoldSpan>{' '}
      stabilito annualmente (nel 2022 pari a € 524,34 euro), la quota cedibile
      sarebbe pari a zero.
      <SpaceTop />
      Nel caso di percezione di più trattamenti pensionistici, la quota cedibile
      viene calcolata considerando la somma degli stessi.
    </AnswerBox>
  );
};

const AnswerBoxTwo: React.FC = () => {
  return (
    <AnswerBox>
      <BoldSpan>
        La durata del prestito non può essere superiore a 10 anni
      </BoldSpan>{' '}
      ed è obbligatorio che il finanziamento sia coperto da una polizza
      assicurativa contro la premorienza.
      <SpaceTop />
      La cessione della pensione può essere eseguita <BoldSpan>solo</BoldSpan> a
      favore di{' '}
      <BoldSpan>Istituti finanziari accreditati presso l'INPS.</BoldSpan>
    </AnswerBox>
  );
};

const AnswerBoxThree: React.FC = () => {
  return (
    <AnswerBox>
      Il pensionato che intenda cedere parte della propria pensione deve{' '}
      <BoldSpan>chiedere</BoldSpan> personalmente <BoldSpan>all'INPS</BoldSpan>{' '}
      il rilascio della{' '}
      <BoldSpan>comunicazione di cedibilità della pensione.</BoldSpan>
      <SpaceTop />
      Tale documento indicherà la quota di pensione cedibile, dovrà essere
      consegnato all'Istituto finanziario e sarà condizione di esecuzione del
      contratto di cessione.
      <SpaceTop />
      L'INPS, dopo avere ricevuto la notifica del contratto di cessione,
      eseguirà delle <BoldSpan>verifiche a tutela del pensionato</BoldSpan>{' '}
      (comunicazione di cedibilità e corrispondenza tra la rata indicata e
      quella effettivamente cedibile; accreditamento dell'Istituto Finanziario;
      tassi di interesse nei limiti della soglia antiusura).
      <SpaceTop />
      All'esito delle verifiche, ed{' '}
      <BoldSpan>entro il terzo mese dalla notifica della cessione</BoldSpan>,
      l'INPS darà corso alle <BoldSpan>trattenute.</BoldSpan>
    </AnswerBox>
  );
};

export { AnswerBoxOne, AnswerBoxThree, AnswerBoxTwo };
