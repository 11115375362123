import { CardContent, CardContentProps, Stack } from '@mui/material';
import Card, { CardProps } from '@mui/material/Card';
import styled from 'styled-components';
import { IconsSize, IconsStyle } from '../../enums';
import { COLORS, FONTS } from '../../utils';
import LBTChip from '../Chip';
import { IconRocketComponent } from '../Icons';
import LBTLabel from '../Label';
import './styles.css';

export enum CardVariant {
  OUTLINED = 'outlined',
  CONTAINED = 'contained',
  COLORED = 'colored',
  NEUTRAL = 'neutral',
  WHITE = 'white',
}

export type LBTCardProps = CardProps & {
  title?: string;
  description?: string;
  tag?: string;
  news: boolean;
  expired: boolean;
  expiringSoon: boolean;
  upcoming: boolean;
  backgroundColor?: string;
  datatestid?: string;
  cardVariant?: CardVariant;
};

const StyledCard = styled(Card)<
  CardProps & {
    backgroundColor?: string;
    cardVariant?: CardVariant;
    datatestid?: string;
    'data-track': string;
  }
>(({ backgroundColor, datatestid, cardVariant, theme, ...props }) => ({
  backgroundColor,
  datatestid,
  theme,
  'data-track': props['data-track'],
  ...(cardVariant === CardVariant.OUTLINED && {
    '&.MuiCard-root': {
      outline: `1px solid ${COLORS.getInstance().BW_GREYS_PALE_MEDIUM}`,
      backgroundColor: COLORS.getInstance().WHITE,
      width: '100%',
      borderRadius: '12px',
      padding: '24px 16px 16px 16px',
      cursor: !!props.onClick ? 'pointer' : 'auto',
      '&:hover': {
        outline: `1px solid ${COLORS.getInstance().BW_GREYS_MEDIUM}`,
      },
      '&:active': {
        outline: `2px solid ${COLORS.getInstance().BW_GREYS_DARK}`,
      },
    },
  }),
  ...(cardVariant === CardVariant.CONTAINED && {
    '&.MuiCard-root': {
      backgroundColor: `${COLORS.getInstance().PRIMARY_MAIN}0A`,
      width: '100%',
      borderRadius: '12px',
      padding: '24px 16px 16px 16px',
      cursor: !!props.onClick ? 'pointer' : 'auto',
    },
    '&:hover': {
      backgroundColor: `${COLORS.getInstance().PRIMARY_MAIN}26`,
    },
    '&:active': {
      outline: `1px solid ${COLORS.getInstance().PRIMARY_DARK}`,
    },
  }),
  ...(cardVariant === CardVariant.NEUTRAL && {
    '&.MuiCard-root': {
      backgroundColor: `${COLORS.getInstance().BW_GREYS_IPERLIGHT}`,
      width: '100%',
      borderRadius: '12px',
      padding: '24px 16px 16px 16px',
      cursor: !!props.onClick ? 'pointer' : 'auto',
    },
    '&:hover': {
      backgroundColor: `${COLORS.getInstance().NEGATIVE}`,
    },
    '&:active': {
      outline: `1px solid ${COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT}`,
    },
  }),
  ...(cardVariant === CardVariant.WHITE && {
    '&.MuiCard-root': {
      backgroundColor: `${COLORS.getInstance().WHITE}`,
      width: '100%',
      borderRadius: '12px',
      padding: '24px 16px 16px 16px',
      cursor: !!props.onClick ? 'pointer' : 'auto',
    },
    '&:hover': {
      outline: `1px solid ${COLORS.getInstance().BW_GREYS_LIGHT}`,
    },
    '&:active': {
      outline: `1px solid ${COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT}`,
    },
  }),
}));

const StyledCardContent = styled(CardContent)<CardContentProps>(() => ({
  '&.MuiCardContent-root': {
    padding: '24px 0px 24px 0px',
  },
}));

const CardChips: React.FC<{
  datatestid?: string;
  news: boolean;
  expired: boolean;
  expiringSoon: boolean;
  upcoming: boolean;
}> = ({ news, expired, expiringSoon, upcoming, datatestid }) => {
  if (expired) {
    return (
      <>
        {news ? (
          <LBTChip
            datatestid={datatestid}
            label="Novità"
            variant="outlined"
            color="error"
            icon={
              <IconRocketComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().ERROR_MAIN}
              />
            }
          />
        ) : (
          <></>
        )}
        <LBTChip
          datatestid={datatestid}
          label="Scaduto"
          variant="filled"
          color="error"
        />
      </>
    );
  } else if (expiringSoon) {
    return (
      <>
        {news ? (
          <LBTChip
            datatestid={datatestid}
            label="Novità"
            variant="outlined"
            color="warning"
            icon={
              <IconRocketComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().WARNING_MAIN}
              />
            }
          />
        ) : (
          <></>
        )}
        <LBTChip
          datatestid={datatestid}
          label="In scadenza"
          variant="filled"
          color="warning"
        />
      </>
    );
  } else if (upcoming) {
    return (
      <>
        {news ? (
          <LBTChip
            datatestid={datatestid}
            label="Novità"
            variant="outlined"
            color="primary"
            icon={
              <IconRocketComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().PRIMARY_MAIN}
              />
            }
          />
        ) : (
          <></>
        )}
        <LBTChip
          datatestid={datatestid}
          label="In arrivo"
          variant="filled"
          color="primary"
        />
      </>
    );
  } else {
    return news ? (
      <LBTChip
        datatestid={datatestid}
        label="Novità"
        variant="outlined"
        color="primary"
        icon={
          <IconRocketComponent
            size={IconsSize.MEDIUM}
            style={IconsStyle.OUTLINE}
            color={COLORS.getInstance().INFO_MAIN}
          />
        }
      />
    ) : (
      <></>
    );
  }
};

const LBTCard: React.FC<LBTCardProps> = ({
  datatestid = '',
  title = '',
  description = '',
  tag,
  news,
  expired,
  expiringSoon,
  upcoming,
  backgroundColor = COLORS.getInstance().WHITE,
  cardVariant = CardVariant.OUTLINED,
  ...props
}: LBTCardProps) => {
  return (
    <StyledCard
      {...props}
      elevation={0}
      backgroundColor={backgroundColor}
      datatestid={`lbt-card-${datatestid}`}
      data-track={datatestid}
      cardVariant={cardVariant}
    >
      <Stack direction="column" height="100%">
        <LBTLabel
          datatestid={datatestid}
          fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
          textAlign="left"
          fontSize="20px"
          lineHeight="26px"
          color={COLORS.getInstance().BW_GREYS_JET_BLACK}
        >
          {title}
        </LBTLabel>
        <StyledCardContent>
          <LBTLabel
            datatestid={datatestid}
            textAlign="left"
            fontSize="14px"
            lineHeight="20px"
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            className="truncate-text"
          >
            {description}
          </LBTLabel>
        </StyledCardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="auto"
        >
          <LBTLabel
            datatestid={datatestid}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            textAlign="left"
            fontSize={'14px'}
            lineHeight={'16px'}
            letterSpacing={'0.75px'}
            sx={{ fontVariant: 'small-caps' }}
          >
            {tag}
          </LBTLabel>
          <Stack direction="row" alignItems="right" spacing="8px">
            <CardChips
              datatestid={datatestid}
              news={news}
              expired={expired}
              expiringSoon={expiringSoon}
              upcoming={upcoming}
            />
          </Stack>
        </Stack>
      </Stack>
    </StyledCard>
  );
};

export default LBTCard;
