import { Typography, TypographyProps, styled } from '@mui/material';
import React from 'react';
import { COLORS, FONTS } from '../../utils';

export type LBTLabelProps = TypographyProps & {
  datatestid?: string;
};

const StyledLabel = styled(Typography)<
  TypographyProps & { datatestid: string; 'data-track': string }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
}));

const LBTLabel: React.FC<LBTLabelProps> = ({
  datatestid = 'lbt-label-',
  fontFamily = FONTS.SOURCE_SANS_PRO,
  fontSize = '13px',
  fontStyle = 'normal',
  fontWeight = 400,
  lineHeight = '18px',
  textAlign = 'center',
  color = COLORS.getInstance().BW_GREYS_JET_BLACK,
  children,
  ...props
}: LBTLabelProps) => {
  return (
    <StyledLabel
      {...props}
      datatestid={`lbt-label-${datatestid}`}
      data-track={datatestid}
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontStyle={fontStyle}
      lineHeight={lineHeight}
      textAlign={textAlign}
      color={color.toString()}
      children={children}
      fontFamily={fontFamily}
    />
  );
};

export default LBTLabel;
