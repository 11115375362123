import {
  AlertTitle,
  AlertTitleProps,
  Palette,
  PaletteColor,
} from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { COLORS, FONTS } from '../../utils';
import IconBookmarkComponent from '../Icons/IconBookmarkComponent';
import IconHappyFaceComponent from '../Icons/IconHappyFaceComponent';
import IconInfoComponent from '../Icons/IconInfoComponent';
import IconWarningComponent from '../Icons/IconWarningComponent';
import { IconsSize, IconsStyle } from '../../enums';
import LBTLabel from '../Label';

interface iconColor {
  color: string;
}

export type LBTAlertProps = AlertProps & {
  title: string;
  message: string;
  onClose?: () => void;
  datatestid: string;
};

// "primary" | "secondary" | "error" | "warning" | "info" | "success"
type PaletteKey = keyof {
  [Key in keyof Palette as Palette[Key] extends PaletteColor
    ? Key
    : never]: true;
};

// "light" | "dark" | "superdark" | "main" | "contrastText"
type PaletteColorKey = keyof PaletteColor;

const StyledAlert = styled(Alert)<
  AlertProps & {
    borderRadius?: string;
    backgroundColor?: string;
    borderColor?: string;
    datatestid: string;
    'data-track': string;
  }
>(
  ({
    theme,
    borderRadius = '15px',
    variant,
    severity,
    datatestid,
    ...props
  }) => ({
    borderRadius,
    datatestid,
    'data-track': props['data-track'],
  }),
);

const StyledAlertTitle = styled(AlertTitle)<AlertTitleProps>(({ theme }) => ({
  textAlign: 'left',
  fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
  fontSize: '17px',
  lineHeight: '25.5px',
}));

const ErrorIcon = ({ color }: iconColor) => {
  return (
    <IconWarningComponent
      size={IconsSize.LARGE}
      style={IconsStyle.OUTLINE}
      color={color}
    />
  );
};

const SuccessIcon = ({ color }: iconColor) => {
  return (
    <IconHappyFaceComponent
      size={IconsSize.LARGE}
      style={IconsStyle.OUTLINE}
      color={color}
    />
  );
};

const InfoIcon = ({ color }: iconColor) => {
  return (
    <IconInfoComponent
      size={IconsSize.LARGE}
      style={IconsStyle.OUTLINE}
      color={color}
    />
  );
};

const NeutralIcon = ({ color }: iconColor) => {
  return (
    <IconBookmarkComponent
      size={IconsSize.LARGE}
      style={IconsStyle.OUTLINE}
      color={color}
    />
  );
};

const LBTAlert: React.FC<LBTAlertProps> = ({
  title,
  message,
  severity,
  icon,
  variant,
  datatestid = '',
  onClose,
  ...props
}: LBTAlertProps) => {
  const alertIcon = React.useMemo(() => {
    if (severity) {
      switch (severity) {
        case 'error':
          return icon ? (
            icon
          ) : (
            <ErrorIcon
              color={
                variant === 'contained'
                  ? COLORS.getInstance().WHITE
                  : COLORS.getInstance().ERROR_MAIN
              }
            />
          );
        case 'success':
          return icon ? (
            icon
          ) : (
            <SuccessIcon
              color={
                variant === 'contained'
                  ? COLORS.getInstance().WHITE
                  : COLORS.getInstance().SUCCESS_MAIN
              }
            />
          );
        case 'info':
          return icon ? (
            icon
          ) : (
            <InfoIcon
              color={
                variant === 'contained'
                  ? COLORS.getInstance().WHITE
                  : COLORS.getInstance().INFO_MAIN
              }
            />
          );
        case 'neutral':
          return icon ? (
            icon
          ) : (
            <NeutralIcon
              color={
                variant === 'contained'
                  ? COLORS.getInstance().WHITE
                  : COLORS.getInstance().NEUTRAL
              }
            />
          );
        default:
          return icon ? icon : undefined;
      }
    }
  }, [severity, icon, variant]);

  return (
    <StyledAlert
      data-track={`lbt-alert-${datatestid}`}
      datatestid={`lbt-alert-${datatestid}`}
      variant={variant}
      severity={severity}
      {...props}
      icon={alertIcon}
      onClose={onClose}
    >
      <StyledAlertTitle>{title}</StyledAlertTitle>
      <LBTLabel
        color={theme =>
          variant === 'outlined'
            ? COLORS.getInstance().WHITE
            : theme.palette[severity as PaletteKey]['main' as PaletteColorKey]
        }
        fontSize="14px"
        lineHeight="16.8px"
        textAlign="left"
      >
        {message}
      </LBTLabel>
    </StyledAlert>
  );
};

export default LBTAlert;
