import { Typography, TypographyProps } from '@mui/material';
import { COLORS, FONTS } from '../../utils';
import { useBreakpoint } from '@laborability/commons';
import { ReactNode } from 'react';

export type LBTTitleProps = TypographyProps & {
  children?: ReactNode;
  color?: string;
};
const LBTTitle: React.FC<LBTTitleProps> = ({
  children,
  color = COLORS.getInstance().BW_GREYS_JET_BLACK,
  ...props
}: LBTTitleProps) => {
  const { isDesktop } = useBreakpoint();
  return (
    <Typography
      {...props}
      variant="h1"
      fontWeight={400}
      fontSize={isDesktop ? '44px' : '30px'}
      fontStyle="normal"
      lineHeight={isDesktop ? '50px' : '36px'}
      textAlign="center"
      children={children}
      maxWidth={'680px'}
      letterSpacing={props.letterSpacing || '-0.1px'}
      fontFamily={FONTS.DELA_GOTHIC_ONE}
      color={color}
    />
  );
};

export default LBTTitle;
