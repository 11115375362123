import { LBTTitle, LBTSubtitle } from '@laborability/components';
import Section from '../../components/Section';

export default function CookiePolicy() {
  return (
    <Section>
      <LBTTitle>Cookie policy</LBTTitle>
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/84fafea8-846c-42ef-bbce-9f497617d80f/cd.js"
        type="text/javascript"
        async
      ></script>
    </Section>
  );
}
